import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from "../../../API";
import { listVisitorRequestsForEscort, listVisitorRequestsForRequestor, sNSPublishVisitCancelled } from 'src/graphql/queries';
import { updateVisitor as updateVisitorMutation} from 'src/graphql/mutations';
import { auditDecorator, getLookupTypeValueId } from 'src/components/utils';
import { LookupTypes, VisitorRequestStatus } from 'src/constants/Constants';
import { debug } from '../../../utils/commonUtils';

export let cancelVisitorRequest = async (visitor_id: string, updatedBy: string): Promise<APIt.Visitor | null> => {
  debug(`cancelVisitorRequest() visitor_id is ${visitor_id} updatedBy is ${updatedBy}`);

  let deletedVisitor: APIt.Visitor | null = null;

  if (!visitor_id || !updatedBy) return deletedVisitor;

  try {
    const cancelledVisitStatusId = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus,'Cancelled');
    const response = await API.graphql(graphqlOperation(updateVisitorMutation,
      {
        input: {
          id: visitor_id,
	        status_id: cancelledVisitStatusId,
          updated_by: updatedBy,
        }
      })) as GraphQLResult<APIt.UpdateVisitorMutation>;
    debug(`cancelVisitorRequest() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.updateVisitor) {
      deletedVisitor = response.data.updateVisitor as APIt.Visitor;
    }
    //if visitor's status id wasn't updated to cancelled, don't send emails
    if (deletedVisitor?.status_id != cancelledVisitStatusId) {
      return (null);
    }
  } catch (e) {
    console.error(`cancelVisitorRequest(): exception is ${JSON.stringify(e)}`);
    throw e;
  }
  debug(`cancelVisitorRequest() deletedVisitor is ${JSON.stringify(deletedVisitor)}`);
  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(sNSPublishVisitCancelled,
      {
        id: visitor_id
      })) as GraphQLResult<APIt.SNSPublishVisitCreatedQuery>;
    if (response && response.data && response.data.SNSPublishVisitCreated) {
      debug(`cancelVisitorRequest() SNSPublishVisitCancelled response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`cancelVisitorRequest() SNSPublishVisitCancelled error is ${JSON.stringify(error)}`);
  }

  return(deletedVisitor);
};
cancelVisitorRequest = auditDecorator('cancelVisitorRequest', cancelVisitorRequest);

export let queryPendingVisitorRequests = async (requestorId: string | undefined): Promise<APIt.VisitorRequest[] | null> => {
  debug(`queryPendingVisitorRequests() requestorId is ${requestorId}`);

  if (!requestorId) return null;

  let
    escortSourceSystemId,
    escortId = requestorId,
    pendingVisitorRequestsForRequestor: APIt.VisitorRequest[] = [],
    pendingVisitorRequestsForEscort: APIt.VisitorRequest[] = [],
    requestorSourceSystemId;

  try {
    requestorSourceSystemId = await getLookupTypeValueId(LookupTypes.RequestorSourceSystem,'PACS');
    escortSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem,'PACS');
    debug(`queryPendingVisitorRequests() requestorSourceSystemId is ${requestorSourceSystemId}`);
  } catch(error) {
    console.error(`queryPendingVisitorRequests() error is ${JSON.stringify(error)}`);
    throw error;
  }

  try {
    const scheduledVisitResponse = await API.graphql(graphqlOperation(listVisitorRequestsForRequestor,
      {
        requestor_id: requestorId,
        requestor_source_system_id: requestorSourceSystemId,
        status: VisitorRequestStatus.ScheduledVisit,
      })) as GraphQLResult<APIt.ListVisitorRequestsForRequestorQuery>;
    debug(`queryPendingVisitorRequests() scheduledVisitResponse is ${JSON.stringify(scheduledVisitResponse)}`);
    if (scheduledVisitResponse.data && scheduledVisitResponse.data.listVisitorRequestsForRequestor) {
      pendingVisitorRequestsForRequestor = scheduledVisitResponse.data.listVisitorRequestsForRequestor as APIt.VisitorRequest[];
    }
  } catch (e) {
    console.error(`queryPendingVisitorRequests(): exception is ${JSON.stringify(e)}`);
    throw e;
  }

  try {
    const scheduledVisitForEscortResponse = await API.graphql(graphqlOperation(listVisitorRequestsForEscort,
      {
        escort_id: escortId,
        escort_source_system_id: escortSourceSystemId,
        status: VisitorRequestStatus.ScheduledVisit,
      })) as GraphQLResult<APIt.ListVisitorRequestsForEscortQuery>;
    debug(`queryPendingVisitorRequests() scheduledVisitForEscortResponse is ${JSON.stringify(scheduledVisitForEscortResponse)}`);
    if (scheduledVisitForEscortResponse.data && scheduledVisitForEscortResponse.data.listVisitorRequestsForEscort) {
      pendingVisitorRequestsForEscort = scheduledVisitForEscortResponse.data.listVisitorRequestsForEscort as APIt.VisitorRequest[];
      // remove duplicates of pendingVisitorRequestForRequestor entries
      pendingVisitorRequestsForEscort = pendingVisitorRequestsForEscort.filter((value1) => {
        let i = pendingVisitorRequestsForRequestor.findIndex((value2) => {
          return(JSON.stringify(value1) == JSON.stringify(value2));
        });
        if (i > -1) return false;
        return true;
      });
    }
  } catch (e) {
    console.error(`queryPendingVisitorRequests(): exception for escort is ${JSON.stringify(e)}`);
    throw e;
  }

  return([...pendingVisitorRequestsForRequestor, ...pendingVisitorRequestsForEscort]);
};
queryPendingVisitorRequests = auditDecorator('queryPendingVisitorRequests', queryPendingVisitorRequests);

export let queryHistoricalVisitorRequests = async (requestorId: string | undefined): Promise<APIt.VisitorRequest[] | null> => {
  debug(`queryHistoricalVisitorRequests() requestorId is ${requestorId}`);

  if (!requestorId) return null;

  let
    escortSourceSystemId,
    escortId = requestorId,
    historicalVisitorRequestsForRequestor: APIt.VisitorRequest[] = [],
    historicalVisitorRequestsForEscort: APIt.VisitorRequest[] = [],
    requestorSourceSystemId;

  try {
    requestorSourceSystemId = await getLookupTypeValueId(LookupTypes.RequestorSourceSystem, 'PACS');
    escortSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem, 'PACS');
    debug(`queryHistoricalVisitorRequests() requestorSourceSystemId is ${requestorSourceSystemId}`);
  } catch (error) {
    console.error(`queryHistoricalVisitorRequests() error is ${JSON.stringify(error)}`);
    throw error;
  }

  for (const status of Object.values(VisitorRequestStatus)) {
    if (status !== VisitorRequestStatus.ScheduledVisit && status !== VisitorRequestStatus.PendingApproval) {

      try {
        const historicalVisitResponse = await API.graphql(graphqlOperation(listVisitorRequestsForRequestor,
          {
            requestor_id: requestorId,
            requestor_source_system_id: requestorSourceSystemId,
            status: status,
          })) as GraphQLResult<APIt.ListVisitorRequestsForRequestorQuery>;
        debug(`queryPendingVisitorRequests() scheduledVisitResponse is ${JSON.stringify(historicalVisitResponse)}`);
        if (historicalVisitResponse.data && historicalVisitResponse.data.listVisitorRequestsForRequestor) {
          historicalVisitorRequestsForRequestor = historicalVisitorRequestsForRequestor.concat(historicalVisitResponse.data.listVisitorRequestsForRequestor as APIt.VisitorRequest[]);
        }
      } catch (e) {
        console.error(`queryPendingVisitorRequests(): exception is ${JSON.stringify(e)}`);
        throw e;
      }

      try {
        const historicalVisitForEscortResponse = await API.graphql(graphqlOperation(listVisitorRequestsForEscort,
          {
            escort_id: escortId,
            escort_source_system_id: escortSourceSystemId,
            status: status,
          })) as GraphQLResult<APIt.ListVisitorRequestsForEscortQuery>;
        debug(`queryPendingVisitorRequests() scheduledVisitForEscortResponse is ${JSON.stringify(historicalVisitForEscortResponse)}`);
        if (historicalVisitForEscortResponse.data && historicalVisitForEscortResponse.data.listVisitorRequestsForEscort) {
          historicalVisitorRequestsForEscort = historicalVisitorRequestsForEscort.concat(historicalVisitForEscortResponse.data.listVisitorRequestsForEscort as APIt.VisitorRequest[]);
          //remove duplicates
          historicalVisitorRequestsForEscort = historicalVisitorRequestsForEscort.filter((value1) => {
            let i = historicalVisitorRequestsForRequestor.findIndex((value2) => {
              return (JSON.stringify(value1) == JSON.stringify(value2));
            });
            if (i > -1) return false;
            return true;
          });
        }
      } catch (e) {
        console.error(`queryHistoricalVisitorRequests(): exception for escort is ${JSON.stringify(e)}`);
        throw e;
      }
    }
  }

  return ([...historicalVisitorRequestsForRequestor, ...historicalVisitorRequestsForEscort]);
};
queryHistoricalVisitorRequests = auditDecorator('queryHistoricalVisitorRequests', queryHistoricalVisitorRequests);