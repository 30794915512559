import React, { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  CollectionPreferences,
  Container,
  DatePicker,
  Flashbar,
  Form,
  FormField,
  Grid,
  Input,
  Modal,
  Multiselect,
  Pagination,
  SpaceBetween,
  Spinner,
  SplitPanel,
  Table,
  TableProps,
  Textarea,
  TextFilter,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from '../../../API';
import { useBundle } from '@amzn/react-arb-tools';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queryRequestEscorts, queryVisitorAccessLevelsForVisitor, queryVisitorActions } from './utils';
import CheckIn from './CheckIn';
import CheckOut from './CheckOut';
import BulkCheckOut from './BulkCheckOut';
import { LookupTypes, VisitorRequestStatus, VisitorTypes, visitorStatusFilterStatuses } from 'src/constants/Constants';
import EscortsTablePanel, { HeaderMessages } from 'src/components/common/Escorts/TablePanel';
import VisitorActionsTablePanel from './VisitorActions/TablePanel';
import VisitorAccessLevelRequestsTablePanel from './AccessLevels/TablePanel';
import RequestAccessLevel from './RequestAccessLevel';
import { createEscort, DateFunctions, deleteEscort, getLookupTypeValueId, queryEmployeeDetails } from 'src/components/utils';
import { ISelectOption } from 'src/interfaces';
import CsvDownloadButton from 'src/components/common/CsvDownloadButton';
import { debug } from 'src/utils/commonUtils';
import { IEscort } from 'src/types';
import * as uuid from 'uuid';


export interface ManageVisitorRequestsTablePanelPropsInterface {
  airSite: boolean;
  cardholdersQuickLinks: string[];
  darkMode: boolean;
  manageVisitorRequests: APIt.VisitorRequest[];
  minDateFilter: string | null;
  maxDateFilter: string | null;
  pageSize: number;
  refreshCallback: Function;
  setCardholdersQuickLinksCallback: Function;
  setMinDateCallback: Function;
  setMaxDateCallback: Function;
  setPageSizePrefCallback: Function;
  setSplitPanelCallback: Function;
  setSplitPanelOpenCallback: Function;
  setStatusFilterCallback: Function;
  setVisitorRequestCallback: Function;
  siteCode: string;
  statusesFilter: ISelectOption[] | undefined;
  username: string;
}

export default function ManageVisitorRequestsTablePanel(props: ManageVisitorRequestsTablePanelPropsInterface ) {
  debug(`ManageVisitorRequestsTablePanel() props is ${JSON.stringify(props)}`);
  const [selectedItems, setSelectedItems] = useState<APIt.VisitorRequest[]>([]);
  const requestEscortsQuery = useQuery({
    enabled: selectedItems?.length == 0,
    onSuccess(data) {
        setCurrentEscorts(data);
        setEscortsSaved(false);
        setEscortsSavedInfo('');
    },
    queryKey: ['requestEscorts'],
    queryFn: async () => {
      if (selectedItems && selectedItems[0]?.request_id) {
        const escorts = await queryRequestEscorts(selectedItems[0].request_id)
        const escortsWithDetails: IEscort[] = [];
        for (let escort of escorts) {
          const employeeDetails = await queryEmployeeDetails(escort.escort_id);
          if (employeeDetails) {
            const expandedEscort: IEscort = {
              __typename: 'EmployeeDetails',
              status: employeeDetails.siteCode,
              firstName: employeeDetails.firstName,
              id: employeeDetails.id,
              idHash: employeeDetails.idHash,
              lastName: employeeDetails.lastName,
              siteCode: employeeDetails.siteCode,
              title: employeeDetails.title,
              username: employeeDetails.username,
            };
            escortsWithDetails.push(expandedEscort);
          }
        } 
        return escortsWithDetails;
      }
      return([]);
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });
  const [actionItem, setActionItem] = useState<APIt.VisitorRequest | null>(null);
  const [allItems, setAllItems] = useState<APIt.VisitorRequest[]>(props.manageVisitorRequests);
  const [bulkCheckOutCount, setBulkCheckOutCount] = useState<number | null>(null);
  const [bulkCheckOutDisabled, setBulkCheckOutDisabled] = useState<boolean>(true);
  const [checkedInRowColor, setCheckedInRowColor] = useState<string>('lightgreen');
  const [currentEscorts, setCurrentEscorts] = useState<IEscort[]>(requestEscortsQuery.data || []);
  const [displayFlashbar, setDisplayFlashbar] = useState<boolean>(false);
  const [escortMissingActiveBadge, setEscortMissingActiveBadge] = useState<boolean | null>(null);
  const [escortMissingSiteAccess, setEscortMissingSiteAccess] = useState<boolean | null>(null);
  const [escortsSaved, setEscortsSaved] = useState<boolean>(false);
  const [escortsSavedInfo, setEscortsSavedInfo] = useState<string>('');
  const [errorText, setErrorText] = useState<string>('');
  const [filterChanged, setFilterChanged] = useState<boolean>(false);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [loadingEscorts, setLoadingEscorts] = useState<boolean>(false);
  const [pageSize, setPageSize ] = useState<number>(props.pageSize);
  const [showBulkCheckOut, setShowBulkCheckOut] = useState<boolean>(false);
  const [showCheckIn, setShowCheckIn] = useState<boolean>(false);
  const [showCheckOut, setShowCheckOut] = useState<boolean>(false);
  const [showSubmissionFailureMessage, setShowSubmissionFailureMessage] = useState<boolean>(false);
  const [submissionFailureErrorText, setSubmissionFailureErrorText] = useState<string>('');
  const [visibleContent, setVisibleContent] = useState<any>();
  const [visitEndDateFilter, setVisitEndDateFilter] = useState<string>(props.maxDateFilter || DateFunctions.getDateFromDays(1));
  const [visitStartDateFilter, setVisitStartDateFilter] = useState<string>(props.minDateFilter || DateFunctions.getDateFromDays(-1));
  const [visitSelectedStatusesFilter, setVisitSelectedStatusesFilter] = useState<ISelectOption[]>(props.statusesFilter || visitorStatusFilterStatuses);
  const [showVisitorAccessLevels, setShowVisitorAccessLevels] = useState<boolean>(false);
  const [showAddVisitorAccessLevel, setShowAddVisitorAccessLevel] = useState<boolean>(false);

  const manageVisitorRequestsTableRef = useRef<HTMLDivElement>(null);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.TablePanel');

  const queryClient = useQueryClient();

  const manageVisitorRequestsQueryState = queryClient.getQueryState(['manageVisitorRequests']);

  const visitorActionsQuery = useQuery({
    queryKey: ['visitorActions'],
    queryFn: async () => {
      if (selectedItems && selectedItems[0]?.visitor_id) return await queryVisitorActions(selectedItems[0].visitor_id);
      return [];
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const visitorAccessLevelsQuery = useQuery({
    enabled: (actionItem !== null),
    onError: (error: Error) => setErrorText(error.message),
    onSuccess: () => setErrorText(''),
    queryKey: ['manageVisitorCheckInVisitorAccessLevels'],
    queryFn: async () => {
      if (!actionItem) return [];
      return await queryVisitorAccessLevelsForVisitor(actionItem?.visitor_id);
    },
    refetchOnWindowFocus: false,
    retry: 3,
  });

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, allPageItems } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-visitor-requests-found') : 'No Visitor Requests Found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'visitor_id' }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const refreshBtnClickHandler = async () => {
    await props.refreshCallback();
  };

  const checkIn = () => {
    setSelectedItems([]);
    setShowCheckIn(false);
    props.refreshCallback();
  };

  const checkOut = () => {
    setSelectedItems([]);
    setShowCheckOut(false);
    props.refreshCallback();
  };

  const bulkCheckOut = () => {
    setSelectedItems([]);
    setShowBulkCheckOut(false);
    props.refreshCallback();
  };

  const cancelEscorts = async () => {
    console.log('cancelEscorts');
    await requestEscortsQuery.refetch();
  };

  const saveEscorts = async () => {
    try{
      if(!selectedItems[0] || !selectedItems[0].request_id)
        return;
      setLoadingEscorts(true);
      const existingEscorts = await queryRequestEscorts(selectedItems[0].request_id);
      const updatedEscorts = currentEscorts;
      const sourceSystem = 'PACS';
      const personSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem, sourceSystem);
      if (!personSourceSystemId) throw `unable to locate requestor source system id for ${sourceSystem}`;
      for (let updatedEscort of updatedEscorts) {
        const existingEscort = existingEscorts?.find(existingEscort => existingEscort.escort_id === updatedEscort.username);
        if (!existingEscort) {
          const createEscortInput: APIt.CreateRequestEscortInput = {
            id: uuid.v4(),
            request_id: selectedItems[0].request_id!,
            escort_id: updatedEscort.username,
            escort_source_system_id: personSourceSystemId,
            created_by: props.username,
          };
          await createEscort(createEscortInput);
        }
      }
      if(!existingEscorts){
        setLoadingEscorts(false);
        return;
      }
      for (let existingEscort of existingEscorts) {
        const updatedEscort = updatedEscorts.find(updatedEscort => updatedEscort.username === existingEscort.escort_id);
        if (!updatedEscort) {
          const deleteEscortInput: APIt.DeleteRequestEscortInput = {
            id: existingEscort.id,
            updated_by: props.username,
          };
          await deleteEscort(deleteEscortInput);
        }
      }
      setLoadingEscorts(false);
      setDisplayFlashbar(true);
      setEscortsSavedInfo(bundle.getMessage('escorts-updated'));
    }catch(error){
      setDisplayFlashbar(false);
      setEscortsSavedInfo(bundle.getMessage('escorts-not-updated'));
    }
    setEscortMissingActiveBadge(null);
    setEscortMissingSiteAccess(null);
    setEscortsSaved(true);
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const Details = () => {
    return(
      <Grid gridDefinition={[{colspan: 2}, {colspan: 2}, {colspan: 2}, {colspan: 2}, {colspan: 3}]}>
        <FormField label={bundle.getMessage('email-address')} >
          <Input value={selectedItems[0]?.email || ''} disabled/>
        </FormField>
        <FormField label={bundle.getMessage('company')} >
          <Input value={selectedItems[0]?.company || ''} disabled/>
        </FormField>
        <FormField label={bundle.getMessage('phone-number')} >
          <Input value={selectedItems[0]?.phone_number || ''} disabled/>
        </FormField>
        <FormField label={bundle.getMessage('requested-by')} >
          <Input value={selectedItems[0]?.requestor_id || ''} disabled/>
        </FormField>
        <FormField label={bundle.getMessage('reason-for-visitor-request')} >
          <Textarea value={selectedItems[0]?.reason || ''} disabled/>
        </FormField>
      </Grid>
    );
  };

  const visitIsPastDue = (visitorRequestWithAction: APIt.VisitorRequest): boolean => {
    const currentDate = new Date;
    let currentDateString = `${currentDate.getFullYear()}-${(currentDate.getMonth()+1).toString().padStart(2,'0')}-${(currentDate.getDate().toString()).padStart(2,'0')}`;
    currentDateString += ` ${currentDate.getHours().toString().padStart(2,'0')}:${currentDate.getMinutes().toString().padStart(2,'0')}:${currentDate.getSeconds().toString().padStart(2,'0')}`;
    return (visitorRequestWithAction.end_date !== null
      && visitorRequestWithAction.end_date !== undefined
      && visitorRequestWithAction.end_date < currentDateString);
  };

  const visitorRequestAction = (visitorRequestWithAction: APIt.VisitorRequest) => {
    const currentDate = new Date;
    let currentDateString = `${currentDate.getFullYear()}-${(currentDate.getMonth()+1).toString().padStart(2,'0')}-${(currentDate.getDate().toString()).padStart(2,'0')}`;
    currentDateString += ` ${currentDate.getHours().toString().padStart(2,'0')}:${currentDate.getMinutes().toString().padStart(2,'0')}:${currentDate.getSeconds().toString().padStart(2,'0')}`;
    switch(visitorRequestWithAction.status) {
      case VisitorRequestStatus.CheckedIn:
        if (visitorRequestWithAction.visitor_type == VisitorTypes.Visitor)
          return(
            <Button
              onClick={() => {
                setActionItem(visitorRequestWithAction);
                setShowCheckOut(true);
              }}
              iconName='lock-private'
            >
              {bundle.getMessage('check-out')}
            </Button>);
        else
          return(
            <SpaceBetween size='xxxs' direction='horizontal'>
              <Button
                onClick={() => {
                  setActionItem(visitorRequestWithAction);
                  setShowCheckOut(true);
                }}
                iconName='lock-private'
              >
                {bundle.getMessage('check-out')}
              </Button>
              <Button
                onClick={() => {
                  setActionItem(visitorRequestWithAction);
                  setShowVisitorAccessLevels(true);
                }}
                iconName={visitorRequestWithAction.all_activated ? 'unlocked' : 'status-pending'}>
                {visitorRequestWithAction.all_activated ? bundle.getMessage('access') : <div style={{ color: "#ff8c00", display: "inline-block" }}>{bundle.getMessage('access')}</div>}
              </Button>
            </SpaceBetween>);
      case VisitorRequestStatus.CheckedOut:
        if (visitorRequestWithAction.visitor_type == VisitorTypes.Visitor)
          return(
            <div
              title={visitIsPastDue(visitorRequestWithAction) ? bundle.getMessage('visit-is-past-due') : ''}
            >
              <Button
                disabled={
                  visitorRequestWithAction.end_date !== null
                  && visitorRequestWithAction.end_date !== undefined
                  && visitorRequestWithAction.end_date < currentDateString}
                iconName='key'
                onClick={() => {
                  setActionItem(visitorRequestWithAction);
                  setShowCheckIn(true);
                }}
              >
                {bundle.getMessage('check-in')}
              </Button>
            </div>);
        else
          return(
            <SpaceBetween size='xxxs' direction='horizontal'>
              <div
                title={visitIsPastDue(visitorRequestWithAction) ? bundle.getMessage('visit-is-past-due') : ''}
              >
                <Button
                  disabled={
                    visitorRequestWithAction.end_date !== null
                    && visitorRequestWithAction.end_date !== undefined
                    && visitorRequestWithAction.end_date < currentDateString}
                  iconName='key'
                  onClick={() => {
                    setActionItem(visitorRequestWithAction);
                    setShowCheckIn(true);
                  }}
                >
                  {bundle.getMessage('check-in')}
                </Button>
              </div>
              <Button
                onClick={() => {
                  setActionItem(visitorRequestWithAction);
                  setShowVisitorAccessLevels(true);
                }}
                iconName={visitorRequestWithAction.all_activated ? 'unlocked' : 'status-pending'}>
                {visitorRequestWithAction.all_activated ? bundle.getMessage('access') : <div style={{ color: "#ff8c00", display: "inline-block" }}>{bundle.getMessage('access')}</div>}
                
              </Button>
            </SpaceBetween>);
      case VisitorRequestStatus.ScheduledVisit:
        if (visitorRequestWithAction.visitor_type == VisitorTypes.Visitor)
          return(
            <div
              title={visitIsPastDue(visitorRequestWithAction) ? bundle.getMessage('visit-is-past-due') : ''}
            >
              <Button
                disabled={
                  visitorRequestWithAction.end_date !== null
                  && visitorRequestWithAction.end_date !== undefined
                  && visitorRequestWithAction.end_date < currentDateString}
                iconName='key'
                onClick={() => {
                  setActionItem(visitorRequestWithAction);
                  setShowCheckIn(true);
                }}
              >
                {bundle.getMessage('check-in')}
              </Button>
            </div>);
        else
          return(
            <SpaceBetween size='xxxs' direction='horizontal'>
              <div
                title={visitIsPastDue(visitorRequestWithAction) ? bundle.getMessage('visit-is-past-due') : ''}
              >
                <Button
                  disabled={
                    visitorRequestWithAction.end_date !== null
                    && visitorRequestWithAction.end_date !== undefined
                    && visitorRequestWithAction.end_date < currentDateString}
                  onClick={() => {
                    setActionItem(visitorRequestWithAction);
                    setShowCheckIn(true);
                  }}
                  iconName='key'
                >
                  {bundle.getMessage('check-in')}
                </Button>
              </div>
              <Button
                onClick={() => {
                  setActionItem(visitorRequestWithAction);
                  setShowVisitorAccessLevels(true);
                }}
                iconName={visitorRequestWithAction.all_activated ? 'unlocked' : 'status-pending'}>
                {visitorRequestWithAction.all_activated ? bundle.getMessage('access') : <div style={{ color: "#ff8c00", display: "inline-block" }}>{bundle.getMessage('access')}</div>}
              </Button>
            </SpaceBetween>);
    }
  };

  const columnDefinitions = (): TableProps.ColumnDefinition<APIt.VisitorRequest>[] => {
    return(
      [
        ...ColumnDefinitions,
        {
          id: 'action',
          header: bundle.getMessage('action'),
          cell: (item: APIt.VisitorRequest) => visitorRequestAction(item as APIt.VisitorRequest),
          minWidth: 175,
          sortingField: 'updated'
        },
      ]);
  };

  useEffect(() => {
    console.log(`ManageVisitorRequestsTablePanel() useEffect()[selectedItems] isBundleLoading is ${isBundleLoading} selectedItems is ${JSON.stringify(selectedItems)}`);
    if (isBundleLoading || selectedItems.length !== 1) {
      props.setSplitPanelCallback(<></>);
      return;
    }
    console.log(`ManageVisitorRequestsTablePanel() useEffect()[selectedItems] calling setSplitPanelCallack`);
    props.setSplitPanelCallback(
      <SplitPanel
        header={`${bundle.getMessage('visitor-request-details')} ${bundle.getMessage('for')} ${selectedItems[0]?.last_name}, ${selectedItems[0]?.first_name}`}
        hidePreferencesButton
        i18nStrings={{
          closeButtonAriaLabel: 'Close panel',
          openButtonAriaLabel: 'Open panel',
          preferencesConfirm: 'Confirm',
          preferencesCancel: 'Cancel',
          preferencesPositionBottom: 'Bottom',
          preferencesPositionDescription: 'Choose the default split panel position for the service.',
          preferencesPositionLabel: 'Split panel position',
          preferencesPositionSide: 'Side',
          preferencesTitle: 'Split panel preferences',
          resizeHandleAriaLabel: 'Resize split panel',
        }}
      >
        <Container
          key={'ManageVisitorAssetDetails'}
          children={
            <>
              <Details/>
              <br/>
              <Form
                actions={
                  selectedItems[0].status != 'Denied'
                  && currentEscorts
                  &&
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      disabled={
                        currentEscorts.length < 1
                        || escortMissingActiveBadge == null
                        || escortMissingSiteAccess == null
                        || escortMissingActiveBadge
                        || escortMissingSiteAccess
                      }
                      loading={
                        requestEscortsQuery.isFetching
                      }
                      onClick={() => {
                        cancelEscorts();
                      }}
                    >
                      {bundle.getMessage('cancel')}
                    </Button>
                    <Button
                      variant='primary'
                      disabled={
                        currentEscorts.length < 1
                        || escortMissingActiveBadge == null
                        || escortMissingSiteAccess == null
                        || escortMissingActiveBadge
                        || escortMissingSiteAccess
                      }
                      loading={
                        requestEscortsQuery.isFetching
                      }
                      onClick={() => {
                        saveEscorts();
                      }}
                    >
                      {bundle.getMessage('save')}
                    </Button>
                  </SpaceBetween>
                }>
                {escortsSaved
                  &&
                  <>
                    <Flashbar
                      items={
                        [
                          {
                            content: escortsSavedInfo,
                            dismissible: true,
                            onDismiss: () => {
                              setEscortsSaved(false);
                              setEscortsSavedInfo('');
                            },
                            type: displayFlashbar ? 'success' : 'error',
                          },
                        ]
                      }
                    />
                  </>
                }
                {currentEscorts && !requestEscortsQuery.isFetching
                  &&
                  <EscortsTablePanel
                    autoFocus={true}
                    cardholdersQuickLinks={props.cardholdersQuickLinks}
                    checkForMissingSiteAccess={true}
                    darkMode={props.darkMode}
                    escorts={currentEscorts}
                    username={props.username}
                    setCardholdersQuickLinksCallback={props.setCardholdersQuickLinksCallback}
                    setEscortsCallback={setCurrentEscorts}
                    setEscortMissingActiveBadgeCallback={setEscortMissingActiveBadge}
                    setEscortMissingSiteAccessCallback={setEscortMissingSiteAccess}
                    siteCode={props.siteCode}
                    headerMessage={HeaderMessages.ESCORTS}
                  />}
              </Form>
              <br/>
              {visitorActionsQuery.data
              &&
              <VisitorActionsTablePanel
                visitorActionsQuery={visitorActionsQuery}
              />}
            </>
          }
        />
      </SplitPanel>);
    props.setSplitPanelOpenCallback(true);
  }, [selectedItems, requestEscortsQuery.data, visitorActionsQuery.data, currentEscorts, escortMissingSiteAccess, escortMissingActiveBadge, escortsSaved]);
  
  const SubmissionFailure = () => {
    return(
      <Alert
        dismissible={true}
        onDismiss={() => setShowSubmissionFailureMessage(false)}
        statusIconAriaLabel='error'
        type='error'
        header={bundle.getMessage('submission-failure-message')}
      >
        {submissionFailureErrorText}
      </Alert> 
    );
  };

  const handleOnSelectionChange = (selectedItems: APIt.VisitorRequest[]) => {
    debug(`ManageVisitorRequestsTablePanel() handleOnSelectionChange() selectedItems is ${JSON.stringify(selectedItems)}`);
    setSelectedItems(selectedItems as APIt.VisitorRequest[]);
  };

  const invalidEndDate = (): boolean => {
    return (visitStartDateFilter == '' || visitEndDateFilter == '' || visitEndDateFilter < visitStartDateFilter);
  };

  const resetFilters = () => {
    setVisitStartDateFilter(DateFunctions.getDateFromDays(-1));
    setVisitEndDateFilter(DateFunctions.getDateFromDays(1));
    setVisitSelectedStatusesFilter(visitorStatusFilterStatuses.filter(v => v.value !== VisitorRequestStatus.CheckedOut));
    setFilterChanged(true);
    actions.setFiltering('');
  };

  const search = async () => {
    debug(`ManageVisitorRequestsTablePanel() search() visitSelectedStatusesFilter is ${JSON.stringify(visitSelectedStatusesFilter)}`)
    await Promise.all(
      [
        props.setMinDateCallback(visitStartDateFilter),
        props.setMaxDateCallback(visitEndDateFilter),
      ]);
    await props.setStatusFilterCallback(visitSelectedStatusesFilter);
    props.refreshCallback();
    setSelectedItems([]);
    setFilterChanged(false);
  };

  const setPreferences = async (details: any) => {
    setVisibleContent(details.visibleContent);
    setPageSize(details.pageSize);
    props.setPageSizePrefCallback(details.pageSize);
  };

  const disableBulkCheckOut = () => {
    let disabled = true;
    const checkedInSelectedCount = selectedItems.filter(si => si.status === VisitorRequestStatus.CheckedIn).length;
    debug(`ManageVisitorRequestsTablePanel() disableBulkCheckOut() checkedInSelectedCount is ${checkedInSelectedCount}`);
    setBulkCheckOutCount(checkedInSelectedCount > 1 ? checkedInSelectedCount : null);
    if (checkedInSelectedCount > 1) disabled = false;
    debug(`ManageVisitorRequestsTablePanel() disableBulkCheckOut() disabled is ${disabled}`);
    setBulkCheckOutDisabled(disabled);
  };
  
  useEffect(() => {
    const refetch = async() => {
      setEscortsSaved(false);
      setEscortsSavedInfo('');
      if (selectedItems[0]?.request_id) await requestEscortsQuery.refetch();
    };
    refetch();
  }, [selectedItems[0]?.request_id]);
  
  useEffect(() => {
    const refetch = async() => {
      if (selectedItems[0]?.visitor_id) await visitorActionsQuery.refetch();
    };
    refetch();
  }, [selectedItems[0]?.visitor_id]);

  useEffect(() => {
    setAllItems(props.manageVisitorRequests);
  }, [props.manageVisitorRequests]);

  useEffect(() => {
    setSelectedItems([]);
  }, [props.airSite]);

  useEffect(() => {
    disableBulkCheckOut();
  }, [selectedItems]);

  useEffect(() => {
    if (manageVisitorRequestsTableRef.current) {
      const tableRows = manageVisitorRequestsTableRef.current.querySelectorAll('tr');
      tableRows.forEach(row => {
        let checkedIn = false;
        let visitorId = '';
        const rowColumns = row.querySelectorAll('td');
        rowColumns.forEach(column => {
          if (column.querySelector('div [data-testid="status"]')?.innerHTML.startsWith('Checked In')) {
            checkedIn = true;
            visitorId = column.querySelector('span')?.innerHTML || '';
          }
        });
        row.style.backgroundColor = '';
        row.style.color = '';
        if (checkedIn && !(selectedItems[0]?.visitor_id == visitorId)) {
          row.style.backgroundColor = checkedInRowColor;
          if (props.darkMode) row.style.color = 'black';
        }
      });
    }
  }, [items]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    {showSubmissionFailureMessage && <SubmissionFailure/>}
    {actionItem && showVisitorAccessLevels
    &&
    <Modal
      header={`${actionItem?.last_name
        ? actionItem.last_name + ', ' + actionItem?.first_name
        : actionItem?.person_id} ${bundle.getMessage('access-levels')}`}
      onDismiss={async () => {setShowVisitorAccessLevels(false); await props.refreshCallback()}}
      size='large'
      visible={showVisitorAccessLevels}
    >
      <VisitorAccessLevelRequestsTablePanel
        airSite={props.airSite}
        closeCallback={async (isAllActivated: boolean) => {
          setShowVisitorAccessLevels(false);
          if(isAllActivated && !actionItem.all_activated){
            await props.refreshCallback();
          }
        }}
        visitorRequest={actionItem}
        visitorAccessLevelsQuery={visitorAccessLevelsQuery}
        refreshCallback={visitorAccessLevelsQuery.refetch}
        readOnly={false}
        showAddAccessLevelCallback={() => setShowAddVisitorAccessLevel(true)}
        vendorDayPassBadgeNum={actionItem?.vendor_day_pass_badge_num || null}
      />
    </Modal>
    }
    {actionItem && showAddVisitorAccessLevel
    &&
    <Modal
      header={`${actionItem?.last_name
        ? actionItem.last_name + ', ' + actionItem?.first_name
        : actionItem?.person_id} ${bundle.getMessage('add-access-level')}`}
      onDismiss={() => setShowVisitorAccessLevels(false)}
      size='large'
      visible={showAddVisitorAccessLevel}
    >
      <RequestAccessLevel
        airSite={props.airSite}
        closeCallback={() => setShowAddVisitorAccessLevel(false)}
        site={props.siteCode}
        username={props.username}
        visitorRequest={actionItem}
      />
    </Modal>
    }
  <div id='tableDiv' hidden={hideTable} ref={manageVisitorRequestsTableRef}>
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions()}
        filter={
          <form onSubmit={e => e.preventDefault()}>
            <SpaceBetween direction='horizontal' size='xs'>
              <FormField label='&#8203;'>
                <TextFilter
                  {...filterProps}
                  filteringAriaLabel={bundle.getMessage('filter-manage-visitor-requests')}
                  filteringPlaceholder={bundle.getMessage('find-manage-visitor-requests')}
                  countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
                />
              </FormField>
              <FormField label={bundle.getMessage('from')}>
                <DatePicker
                  onChange={({ detail }) => 
                    {
                      setVisitStartDateFilter(detail.value);
                      setFilterChanged(true);
                    }
                  }
                  value={visitStartDateFilter}
                  todayAriaLabel={''}
                  nextMonthAriaLabel={''}
                  previousMonthAriaLabel={''}
                />
              </FormField>
              <FormField label={bundle.getMessage('to')}>
                <DatePicker
                  onChange={({ detail }) => 
                    {
                      setVisitEndDateFilter(detail.value);
                      setFilterChanged(true);
                    }
                  }
                  value={visitEndDateFilter}
                  todayAriaLabel={''}
                  nextMonthAriaLabel={''}
                  previousMonthAriaLabel={''}
                  invalid={invalidEndDate()}
                />
              </FormField>
              <FormField label={bundle.getMessage('visit-status')}>
                <Multiselect
                  expandToViewport
                  hideTokens
                  onChange={({ detail }) =>
                    {
                      const selectedOptions = detail.selectedOptions.map(v => { return { description: v.label, label: v.label!, value: v.value! } });
                      setVisitSelectedStatusesFilter(selectedOptions || []);
                      setFilterChanged(true);
                    }
                  }
                  options={visitorStatusFilterStatuses}
                  placeholder={(visitSelectedStatusesFilter.map((value,index,array) => ` ${value.label}`).toString())}
                  selectedOptions={visitSelectedStatusesFilter}
                />
              </FormField>
              <FormField label='&#8203;'>
                <Button
                  formAction='none'
                  iconName='redo'
                  onClick={resetFilters}
                >
                  {bundle.getMessage('reset')}
                </Button>
              </FormField>
              <FormField label='&#8203;'>
                <Button
                  disabled={invalidEndDate()}
                  iconAlign='right'
                  iconName='search'
                  loading={manageVisitorRequestsQueryState?.fetchStatus == 'fetching'}
                  onClick={search}
                  variant={filterChanged ? 'primary' : 'normal'}
                >
                  {bundle.getMessage('search')}
                </Button>
              </FormField>
            </SpaceBetween>
          </form>
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <SpaceBetween direction='horizontal' size='s'>
                {bulkCheckOutCount && bulkCheckOutCount > 1
                &&
                <Button
                  disabled={bulkCheckOutDisabled}
                  iconAlign='right'
                  iconName='lock-private'
                  loading={manageVisitorRequestsQueryState?.fetchStatus == 'fetching'}
                  onClick={() => setShowBulkCheckOut(true)}
                  variant='normal'
                >
                  {bundle.getMessage('bulk-check-out')} {bulkCheckOutCount}
                </Button>}
                <CsvDownloadButton
                  data={allPageItems}
                  delimiter={','}
                  disabled={manageVisitorRequestsQueryState?.fetchStatus == 'fetching' || allPageItems.length < 1}
                  filename={`ManageVisitors-${new Date().toISOString()}`}
                />
                <Button
                  loading={manageVisitorRequestsQueryState?.fetchStatus == 'fetching'}
                  key='refreshBtn'
                  onClick={refreshBtnClickHandler}
                  iconName='refresh'
                >
                  {bundle.getMessage('refresh')}
                </Button>
              </SpaceBetween>
            }
          >
            {props.siteCode} {bundle.getMessage('manage-visitor-requests')}
          </Header>
        }
        items={items}
        loading={manageVisitorRequestsQueryState?.fetchStatus == 'fetching'}
        empty={manageVisitorRequestsQueryState?.status == 'error'
          ?
            <Alert type='error'>
              {`${bundle.getMessage('query-failure')} (${typeof manageVisitorRequestsQueryState?.error === 'object'
                 ? JSON.stringify(manageVisitorRequestsQueryState.error)
                 : manageVisitorRequestsQueryState?.error})`}
            </Alert>
          : bundle.getMessage('no-visitor-requests-found')
        }
        loadingText={bundle.getMessage('loading-manage-visitor-requests')}
        onSelectionChange={({ detail }) => {
          handleOnSelectionChange(detail.selectedItems);
        }}
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            title={bundle.getMessage('preferences')}
            confirmLabel={bundle.getMessage('confirm')}
            cancelLabel={bundle.getMessage('cancel')}
            preferences={{
              pageSize: pageSize,
              visibleContent: visibleContent,
            }}
            pageSizePreference={{
              title: bundle.getMessage('select-page-size'),
              options: [
                { value: 5, label: '5' },
                { value: 10, label: '10' },
                { value: 15, label: '15' },
                { value: 25, label: '25' },
                { value: 50, label: '50' },
                { value: 100, label: '100' }
              ],
            }}
            visibleContentPreference={{
              title: bundle.getMessage('select-optional-content'),
              options: [
                {
                  label: '',
                  options: [
                    {
                      id: 'visitor_id',
                      label: 'Visitor ID',
                    },
                  ]
                }
              ]
            }}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType='multi'
        stickyHeader={true}
        trackBy='visitor_id'
        visibleColumns={columnDefinitions().map(cd => {
          if (cd.id == 'visitor_id' && !visibleContent?.includes('visitor_id')) return '';
          if (cd.id && cd.id !== 'requestedBy') return cd.id;
          return '';
        })}
      />
    </div>
    {
      actionItem && showCheckIn
      &&
      <Modal
        onDismiss={() => setShowCheckIn(false)}
        header={`${bundle.getMessage('check-in')} - ${actionItem.visitor_type}`}
        size='large'
        visible={showCheckIn}
      >
        <CheckIn
          airSite={props.airSite}
          darkMode={props.darkMode}
          visitorRequest={actionItem}
          cancelCheckInCallback={() => {
            setActionItem(null);
            setShowCheckIn(false);
          }}
          checkInCallback={checkIn}
          requestAllActivatedCallback = {async (request_id: String) => {
            await props.refreshCallback();
          }}
          siteCode={props.siteCode}
          submissionProcessing={false}
          username={props.username}
        />
      </Modal>
    }
    {
      actionItem && showCheckOut
      &&
      <Modal
        header={`${bundle.getMessage('check-out')} - ${actionItem.visitor_type}`}
        onDismiss={() => setShowCheckOut(false)}
        size='medium'
        visible={showCheckOut}
      >
        <CheckOut
          airSite={props.airSite}
          cancelCheckOutCallback={() => setShowCheckOut(false)}
          checkOutCallback={checkOut}
          siteCode={props.siteCode}
          submissionProcessing={false}
          username={props.username}
          visitorRequest={actionItem}
        />
      </Modal>
    }
    {
      showBulkCheckOut
      &&
      <Modal
        header={`${bundle.getMessage('check-out')}`}
        onDismiss={() => setShowBulkCheckOut(false)}
        size={selectedItems.filter(si => si.status === VisitorRequestStatus.CheckedIn).length > 2 ? 'max' : 'large'}
        visible={showBulkCheckOut}
      >
        <BulkCheckOut
          airSite={false}
          bulkCheckOutCallback={bulkCheckOut}
          cancelBulkCheckOutCallback={() => setShowBulkCheckOut(false)}
          siteCode={props.siteCode}
          submissionProcessing={false}
          username={props.username}
          visitorRequests={
            selectedItems.filter(si => si.status === VisitorRequestStatus.CheckedIn)
            .sort((a, b) => {
              const aName = a.first_name + ' ' + a.last_name;
              const bName = b.first_name + ' ' + b.last_name;
              return aName.localeCompare(bName);
            })
          }
        />
      </Modal>
    }
    </>
  );
}