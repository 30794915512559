import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from '../../../API';
import {
  createVisitorAsset as createVisitorAssetMutation,
  createVisitorAssetApproval as createVisitorAssetApprovalMutation,
} from '../../../graphql/mutations';
import { CreateVisitorAssetMutation, GetVisitorQuery } from 'src/API';
import { getVisitor, listVisitorAssetApprovalsForVisitorAsset, sNSPublishAssetRequestCreated } from 'src/graphql/queries';
import { auditDecorator, getAccessLevelApproverGroups, getApprovers, getLookupTypeValueId, queryEmployeeDetails } from 'src/components/utils';
import { createVisitor } from 'src/components/SelfService/utils';
import * as uuid from 'uuid';
import { ApprovalStatus, LookupTypes, VisitorTypes } from 'src/constants/Constants';
import { updateVisitorAsset } from 'src/components/Management/ManageAssets/utils';
import { debug } from '../../../utils/commonUtils';

export let queryVisitorAssetApprovals = async (visitorAssetId: string): Promise<APIt.VisitorAssetApproval[]> => {
  debug(`queryVisitorAssetApprovals() visitorAssetId is ${visitorAssetId}`);

  let visitorAssetApprovals: APIt.VisitorAssetApproval[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAssetApprovalsForVisitorAsset,
      {
        visitor_asset_id: visitorAssetId
      })) as GraphQLResult<APIt.ListVisitorAssetApprovalsForVisitorAssetQuery>;
    if (response.data && response.data.listVisitorAssetApprovalsForVisitorAsset) {
      visitorAssetApprovals = response.data.listVisitorAssetApprovalsForVisitorAsset as unknown as APIt.VisitorAssetApproval[];
    }
  } catch (e) {
    console.error(`queryVisitorAssetApprovals() exception is ${JSON.stringify(e)}`);
    throw e;
  }

  return(visitorAssetApprovals);
};
queryVisitorAssetApprovals = auditDecorator('queryVisitorAssetApprovals', queryVisitorAssetApprovals);

const createVisitorAssetApproval = async (visitorAssetApprovalInput: APIt.CreateVisitorAssetApprovalInput): Promise<APIt.VisitorAssetApproval | null> => {
  debug(`createVisitorAssetApproval() begin`);
  debug(`createVisitorAssetApproval() visitorAssetApprovalInput is ${JSON.stringify(visitorAssetApprovalInput)}`);

  let createdVisitorAssetApproval: APIt.VisitorAssetApproval | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createVisitorAssetApprovalMutation,
      {
        input: visitorAssetApprovalInput
      })) as GraphQLResult<APIt.CreateVisitorAssetApprovalMutation>;
    if (response && response.data && response.data.createVisitorAssetApproval) {
      createdVisitorAssetApproval = response.data.createVisitorAssetApproval;
    }
  } catch(error) {
    console.error(`createVisitorAssetApproval() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return createdVisitorAssetApproval;
};

export let createVisitorAsset = async (visitorAsset: APIt.VisitorAsset, disableAutoApproval: boolean = false): Promise<APIt.VisitorAsset | null> => {
  debug(`createVisitorAsset() visitorAsset is ${JSON.stringify(visitorAsset)}`);

  let visitorId = visitorAsset.visitor_id;

  try {
    if (visitorAsset.visitor_id === null || visitorAsset.visitor_id === '' || visitorAsset.visitor_id === undefined) {
      const visitorTypeId = await getLookupTypeValueId(LookupTypes.VisitorType, VisitorTypes.Employee);
      const personSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem, 'PACS');
      const employeeDetails = await queryEmployeeDetails(visitorAsset.person_id!);
      visitorId = uuid.v4();
      const createVisitorInput: APIt.CreateVisitorInput = {
        created_by: visitorAsset.created_by,
        first_name: employeeDetails?.firstName,
        id: visitorId,
        last_name: employeeDetails?.lastName,
        person_id: visitorAsset.person_id,
        person_source_system_id: personSourceSystemId,
        requestUUID: null,
        visitor_type_id: visitorTypeId!,
      };
      debug(`createVisitorAsset() calling createVisitor visitorId is ${visitorId}`);
      await createVisitor(createVisitorInput);
    }
    debug(`createVisitorAsset() calling getVisitor visitorId is ${visitorId}`);
    const getVisitorResponse = await API.graphql(graphqlOperation(getVisitor,
      {
        id: visitorId,
      })) as GraphQLResult<GetVisitorQuery>;
    debug(`createVisitorAsset() getVisitorResponse is ${JSON.stringify(getVisitorResponse)}`);
    if (getVisitorResponse.data?.getVisitor?.person_id !== visitorAsset.person_id) {
      debug(`createVisitorAsset() creating new visitor for visitor asset`);
      const visitorTypeId = await getLookupTypeValueId(LookupTypes.VisitorType, VisitorTypes.Employee);
      const personSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem, 'PACS');
      const createVisitorInput: APIt.CreateVisitorInput = {
        created_by: visitorAsset.created_by,
        id: uuid.v4(),
        person_id: visitorAsset.person_id,
        person_source_system_id: personSourceSystemId,
        visitor_type_id: visitorTypeId!,
      };
      const visitor = await createVisitor(createVisitorInput);
      if (!visitor) throw new Error('createVisitorAsset() failed to create visitor');
      visitorAsset.visitor_id = visitor?.id;
    }
  } catch(error) {
    console.error('createVisitorAsset() error is', error);
    throw error;
  }

  const input = {
    approval_status_id: visitorAsset.approval_status_id,
    asset_type_id: visitorAsset.asset_type_id,
    created_by: visitorAsset.created_by,
    description: visitorAsset.description,
    end_date: visitorAsset.end_date,
    make: visitorAsset.make,
    model: visitorAsset.model,
    permanent_flag: visitorAsset.permanent_flag,
    serial_num: visitorAsset.serial_num,
    site_id: visitorAsset.site_id,
    site_source_system_id: visitorAsset.site_source_system_id,
    start_date: visitorAsset.start_date,
    visitor_id: visitorId,
  };
  debug(`createVisitorAsset() input is ${JSON.stringify(input)}`);

  let createdVisitorAsset: APIt.VisitorAsset | null = null, autoApprove = false;

  try {
    debug('createVisitorAsset() calling createVisitorAssetMutation');
    const response = await API.graphql(graphqlOperation(createVisitorAssetMutation,
      {
        input: input
      })) as GraphQLResult<CreateVisitorAssetMutation>;
    debug(`createVisitorAsset() response is ${JSON.stringify(response)}`);
    if (response && response.data && response.data.createVisitorAsset) {
      createdVisitorAsset = response.data.createVisitorAsset;
      if (!createdVisitorAsset) throw new Error('visitor asset not created');
      if (visitorAsset.approval_status != ApprovalStatus.Approved) {
        const approverSourceSystemId = await getLookupTypeValueId(LookupTypes.ApproverSourceSystem, 'PACS');
        if (!approverSourceSystemId) throw new Error(`Unable to locate approver source system id value for PACS`);
        debug(`createVisitorAsset() approverSourceSystemId is ${approverSourceSystemId}`);

        const pendingApprovalAssetStatusCodeId = await getLookupTypeValueId(LookupTypes.AssetApprovalStatus,ApprovalStatus.PendingApproval);
        debug(`createVisitorAsset() pendingApprovalAssetStatusCodeId is ${pendingApprovalAssetStatusCodeId}`);
        if (!pendingApprovalAssetStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.PendingApproval}`);

        const approvedAssetStatusCodeId = await getLookupTypeValueId(LookupTypes.AssetApprovalStatus,ApprovalStatus.Approved);
        debug(`createVisitorAsset() approvedAssetStatusCodeId is ${approvedAssetStatusCodeId}`);
        if (!approvedAssetStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.Approved}`);

        const approverGroups = await getAccessLevelApproverGroups(`${visitorAsset.site_id}-RC-ASSET-APPROVERS`);
        debug(`createVisitorAsset() approverGroups is ${JSON.stringify(approverGroups)}`);
        debug(`createVisitorAsset() approverGroups.length is ${approverGroups.length}`);

        let approvers: string[] = [];
        const promises: Promise<APIt.VisitorAssetApproval | APIt.VisitorAsset | null>[] = [];
        if (approverGroups.length > 0) {
          for (let approverGroup of approverGroups) {
            debug(`createVisitorAsset() approverGroup is ${approverGroup}`);
            approvers.push(...(await getApprovers(approverGroup)));
          }
          debug(`createVisitorAsset() approvers is ${JSON.stringify(approvers.sort())}`);
          const uniqueApprovers = Array.from(new Set(approvers));
          debug(`createVisitorAsset() uniqueApprovers is ${JSON.stringify(uniqueApprovers.sort())}`);
          if (uniqueApprovers.includes(visitorAsset.created_by) && !disableAutoApproval) {
            debug(`createVisitorAsset() uniqueApprovers includes ${visitorAsset.created_by}`);
            autoApprove = true;
            approvers = [visitorAsset.created_by];
            promises.push(updateVisitorAsset(
              {
	              approval_status_id: approvedAssetStatusCodeId,
	              asset_type_id: createdVisitorAsset.asset_type_id,
	              denial_reason_id: createdVisitorAsset.denial_reason,
	              description: createdVisitorAsset.description,
	              end_date: createdVisitorAsset.end_date,
	              id: createdVisitorAsset.id,
	              permanent_flag: createdVisitorAsset.permanent_flag,
	              serial_num: createdVisitorAsset.serial_num,
	              site_id: createdVisitorAsset.site_id,
	              site_source_system_id: createdVisitorAsset.site_source_system_id,
	              start_date: createdVisitorAsset.start_date,
	              updated_by: createdVisitorAsset.updated_by,
	              visitor_id: createdVisitorAsset.visitor_id,
	              notes: createdVisitorAsset.notes,
              }));
          }
          for (let approver of uniqueApprovers.sort()) {
            debug(`createVisitorAsset() approver is ${approver}`);
            const createVisitorAssetApprovalInput: APIt.CreateVisitorAssetApprovalInput = {
              approver_id: approver,
              approver_source_system_id: approverSourceSystemId!,
              created_by: createdVisitorAsset!.created_by,
              id: uuid.v4(),
              visitor_asset_id: createdVisitorAsset!.id,
              status_code_id: autoApprove ? approvedAssetStatusCodeId : pendingApprovalAssetStatusCodeId,
            };
            debug(`createVisitorAsset() createVisitorAssetApprovalInput is ${JSON.stringify(createVisitorAssetApprovalInput)}`);
            promises.push(createVisitorAssetApproval(createVisitorAssetApprovalInput));
          }
        } else {
          debug(`createVisitorAsset() approvers does not include ${visitorAsset.created_by}`);
          approvers = await getApprovers(`${visitorAsset.site_id}-RC-ASSET-APPROVERS`);
          if (approvers.includes(visitorAsset.created_by)) {
            autoApprove = true;
            approvers = [visitorAsset.created_by];
            promises.push(updateVisitorAsset(
              {
	              approval_status_id: approvedAssetStatusCodeId,
	              asset_type_id: createdVisitorAsset.asset_type_id,
	              denial_reason_id: createdVisitorAsset.denial_reason,
	              description: createdVisitorAsset.description,
	              end_date: createdVisitorAsset.end_date,
	              id: createdVisitorAsset.id,
	              permanent_flag: createdVisitorAsset.permanent_flag,
	              serial_num: createdVisitorAsset.serial_num,
	              site_id: createdVisitorAsset.site_id,
	              site_source_system_id: createdVisitorAsset.site_source_system_id,
	              start_date: createdVisitorAsset.start_date,
	              updated_by: createdVisitorAsset.updated_by,
	              visitor_id: createdVisitorAsset.visitor_id,
	              notes: createdVisitorAsset.notes,
              }));
          }
          debug(`createVisitorAsset() approvers is ${JSON.stringify(approvers)}`);
          for (let approver of approvers) {
            debug(`createVisitorAsset() approver is ${approver}`);
            const createVisitorAssetApprovalInput: APIt.CreateVisitorAssetApprovalInput = {
              approver_id: approver,
              approver_source_system_id: approverSourceSystemId!,
              created_by: createdVisitorAsset!.created_by,
              id: uuid.v4(),
              visitor_asset_id: createdVisitorAsset!.id,
              status_code_id: autoApprove ? approvedAssetStatusCodeId : pendingApprovalAssetStatusCodeId,
            };
            promises.push(createVisitorAssetApproval(createVisitorAssetApprovalInput));
          }
        }
        debug(`createVisitorAsset() promises.length is ${promises.length}`);
        await Promise.all(promises);
      }
    }
  } catch(error) {
    console.error(error);
    throw error;
  }

  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(sNSPublishAssetRequestCreated,
      {
        visitorAssetId: createdVisitorAsset!.id
      })) as GraphQLResult<APIt.SNSPublishAssetRequestCreatedQuery>;
    if (response && response.data && response.data.SNSPublishAssetRequestCreated) {
      debug(`createVisitorAsset() response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`createVisitorAsset() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return createdVisitorAsset;
};
createVisitorAsset = auditDecorator('createVisitorAsset', createVisitorAsset);