import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult, GraphQLSubscription } from '@aws-amplify/api';
import * as APIt from 'src/API';
import {
  auditDecorator,
  createCardholder,
  createEscort,
  createVisitorAccessLevel,
  createVisitorAccessLevelApproval,
  deleteEscort,
  getAccessLevelApproverGroups,
  getApprovers,
  getLookupTypeValueId,
  getLookupTypeValues,
  queryEmployeeDetails,
  queryLookupTypeValueForTypeAndDescription,
  sqlEscapeString,
  throttle,
} from 'src/components/utils';
import {
  getBadgeAssignment,
  getRequestEscortsForRequest,
  getVisitor,
  getVisitorRequestForNameAndVendorDayPassBadgeNum,
  getVisitorRequestForVendorDayPassBadgeNum,
  listVisitorAccessLevelsForVisitor,
  listVisitorActions,
  sNSPublishAccessLevelRequestCreated,
  sNSPublishVisitorDeparted,
  sNSPublishVisitorReceived,
} from 'src/graphql/queries';
import {
  accessControlRequestStatus,
  badgeAccessLevel,
  badgeAssignment,
  cardholder,
  createVisitor,
  createVisitorAction,
  updateVisitor,
} from 'src/graphql/mutations';
import {
  Actions,
  ApprovalStatus,
  BadgeStatus,
  BadgeTypes,
  CheckInSteps,
  CheckOutSteps,
  LookupTypes,
  UnicornPACSAPIv2Priority,
  VisitorRequestStatus,
  VisitorTypes,
  WelcomeApplicationSettings,
} from 'src/constants/Constants';
import { updateVisitorAccessLevel } from '../ManageAccessRequests/utils';
import * as uuid from 'uuid';
import { debug } from 'src/utils/commonUtils';
import { IEscort } from 'src/types';
import { onVisitorRequestUpdate } from 'src/graphql/subscriptions';

export let addRequestAccessLevel = async (
  params: {
    requestId: string,
    requestor: string,
    visitorAccessLevel: APIt.CreateVisitorAccessLevelInput}): Promise<void> =>
{
  debug(`addRequestAccessLevel() requestId is ${params.requestId} requestor is ${params.requestor} visitorRequest is ${JSON.stringify(params.visitorAccessLevel)}`);

  let autoApprove = false;

  const approverGroups = await getAccessLevelApproverGroups(params.visitorAccessLevel.access_level_name);
  debug(`addRequestAccessLevel() approverGroups is ${JSON.stringify(approverGroups)}`);
  const approverSourceSystemId = await getLookupTypeValueId('Approver Source System', 'PACS');
  debug(`addRequestAccessLevel() approverSourceSystemId is ${approverSourceSystemId}`);
  const pendingApprovalAccessLevelStatusCodeId = await getLookupTypeValueId('Access Level Approval Status', ApprovalStatus.PendingApproval);
  debug(`addRequestAccessLevel() pendingApprovalAccessLevelStatusCodeId is ${pendingApprovalAccessLevelStatusCodeId}`);
  const approvedAccessLevelStatusCodeId = await getLookupTypeValueId('Access Level Approval Status', ApprovalStatus.Approved);
  if (!pendingApprovalAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.PendingApproval}`);
  if (!approvedAccessLevelStatusCodeId) throw new Error(`Unable to locate status code id value for ${ApprovalStatus.Approved}`);
  debug(`addRequestAccessLevel() approvedAccessLevelStatusCodeId is ${approvedAccessLevelStatusCodeId}`);
  const createdVisitorAccessLevelApprovals: APIt.CreateVisitorAccessLevelApprovalInput[] = [];
  let createVisitorAccessLevelApprovalCalls = [];
  let approvers = new Set();
  for (let approverGroup of approverGroups) {
    debug(`addRequestAccessLevel() approverGroup is ${approverGroup}`);
    (await getApprovers(approverGroup)).forEach(approver => approvers.add(approver));
    if (approvers.has(params.requestor)) {
      autoApprove = true;
      break;
    }
  }
  debug(`addRequestAccessLevel() autoApprove is ${autoApprove}`);
  debug(`addRequestAccessLevel() approvers is ${JSON.stringify(approvers)}`);
  if (autoApprove) {
    approvers = new Set([params.requestor]);
    params.visitorAccessLevel.status_code_id = approvedAccessLevelStatusCodeId;
  }
  await createVisitorAccessLevel(params.visitorAccessLevel);

  for (let approver of Array.from(approvers)) {
    if(typeof approver != 'string')
      continue;
    debug(`addRequestAccessLevel() approver is ${approver}`);
    if (createdVisitorAccessLevelApprovals.find((a) =>
      (a.approver_id == approver
      && a.approver_source_system_id == approverSourceSystemId
      && a.visitor_access_level_id == params.visitorAccessLevel.id)) == undefined)
    {
      const createVisitorAccessLevelApprovalInput: APIt.CreateVisitorAccessLevelApprovalInput = {
        approver_id: approver,
        approver_source_system_id: approverSourceSystemId!,
        created_by: params.visitorAccessLevel.created_by,
        id: uuid.v4(),
        visitor_access_level_id: params.visitorAccessLevel.id,
        status_code_id: autoApprove ? approvedAccessLevelStatusCodeId : pendingApprovalAccessLevelStatusCodeId,
      };
      createdVisitorAccessLevelApprovals.push(createVisitorAccessLevelApprovalInput);
      createVisitorAccessLevelApprovalCalls.push(createVisitorAccessLevelApproval(createVisitorAccessLevelApprovalInput));
    }
  };
  await Promise.all(createVisitorAccessLevelApprovalCalls);

  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(sNSPublishAccessLevelRequestCreated,
      {
        requestId: params.requestId,
      })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestCreatedQuery>;
    if (response && response.data && response.data.SNSPublishAccessLevelRequestCreated) {
      debug(`addRequestAccessLevel() response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`addRequestAccessLevel() error is ${JSON.stringify(error)}`);
    throw error;
  }
}
addRequestAccessLevel = auditDecorator('addRequestAccessLevel', addRequestAccessLevel);

let addBadgeAssignment = async (visitorRequest: APIt.VisitorRequest) => {
  debug(`addBadgeAssignment() visitorRequest is ${JSON.stringify(visitorRequest)}`);

  if (!visitorRequest.vendor_day_pass_badge_num || visitorRequest.vendor_day_pass_badge_num == 'unassigned')
    throw new Error('vendor_day_pass_badge_num must be provided');

  try {
    let hoursExtBadgeActivation = 0;
    let hoursExtBadgeDeactivation = 0;
    try {
      hoursExtBadgeActivation = parseInt(
        (await queryLookupTypeValueForTypeAndDescription(
          LookupTypes.WelcomeApplicationSettings,
          WelcomeApplicationSettings.HoursExtensionBadgeActivate)).value);
      hoursExtBadgeDeactivation = parseInt(
        (await queryLookupTypeValueForTypeAndDescription(
          LookupTypes.WelcomeApplicationSettings,
          WelcomeApplicationSettings.HoursExtensionBadgeDeactivation)).value);
    } catch(error) {
      console.error('Error getting Hours Extension - Badge... lookup type values', error);
    }
    debug(`addBadgeAssignment() hoursExtBadgeActivation is ${hoursExtBadgeActivation}`);
    debug(`addBadgeAssignment() hoursExtBadgeDeactivation is ${hoursExtBadgeDeactivation}`);
    const badgeAssignmentInput = {
      methodName: 'AddBadgeAssignment',
      params: {
        EmployeeID: undefined,
        PersonID: undefined,
        BadgeNumber: parseInt(visitorRequest.vendor_day_pass_badge_num),
        BadgeStatus: BadgeStatus.Active,
        BadgeType: BadgeTypes.VendorLoanerDayPass,
        IssueTimeEpoch: visitorRequest.start_date
          ? (Date.parse(visitorRequest.start_date+'Z')/1000) - (hoursExtBadgeActivation*60*60)
          : undefined,
        ExpireTimeEpoch: visitorRequest.end_date
          ? (Date.parse(visitorRequest.end_date+'Z')/1000) + (hoursExtBadgeDeactivation*60*60)
          : undefined,
        Priority: UnicornPACSAPIv2Priority.HIGH,
        WelcomeID: visitorRequest.visitor_id,
      }
    };
    debug(`addBadgeAssignment() badgeAssignmentInput is ${JSON.stringify(badgeAssignmentInput)}`);
    const badgeAssignmentResponse = await API.graphql(graphqlOperation(badgeAssignment,
      {
        input: badgeAssignmentInput
      })) as GraphQLResult<APIt.BadgeAssignmentMutation>;
    debug(`addBadgeAssignment() badgeAssignmentResponse is ${JSON.stringify(badgeAssignmentResponse)}`);
    if (badgeAssignmentResponse.data?.badgeAssignment?.statusCode !== 202) throw new Error('Badge Assignment Failure');
    let updatedVisitor: APIt.Visitor | null = null;
    const updateVisitorInput = {
      id: visitorRequest.visitor_id,
      badge_requestUUID: badgeAssignmentResponse.data.badgeAssignment.requestUUID,
      updated_by: visitorRequest.updated_by,
    };
    debug(`addBadgeAssignment() updateVisitorInput is ${JSON.stringify(updateVisitorInput)}`);
    const response = await API.graphql(graphqlOperation(updateVisitor,
      {
        input: updateVisitorInput
      })) as GraphQLResult<APIt.UpdateVisitorMutation>;
    if (response && response.data && response.data.updateVisitor) {
      updatedVisitor = response.data.updateVisitor;
    }
    if (!updatedVisitor) throw new Error('Unable to update visitor.');
  } catch(error: any) {
    console.error(`addBadgeAssignment(): AddBadgeAssignment exception is ${error} ${JSON.stringify(error)}`);
    if (typeof error == 'object' && error && error.errors) throw new Error(`Failed to assign badge: ${error.errors[0].message}`);
    throw new Error(`Failed to assign badge: ${error}`);
  }
}
addBadgeAssignment = auditDecorator('addBadgeAssignment', addBadgeAssignment);

let addVisitorAction = async(createVisitorActionInput: APIt.CreateVisitorActionInput) => {
  debug(`addVisitorAction() createVisitorActionInput is ${JSON.stringify(createVisitorActionInput)}`);

  try {
    const createVisitorActionResponse = await API.graphql(graphqlOperation(createVisitorAction,
      {
        input: createVisitorActionInput
      })) as GraphQLResult<APIt.CreateVisitorActionMutation>;
    debug(`addVisitorAction() getBadgeAssignmentResponse is ${JSON.stringify(createVisitorActionResponse)}`);
    if (createVisitorActionResponse.data && createVisitorActionResponse.data.createVisitorAction) {
      const visitorAction = createVisitorActionResponse.data.createVisitorAction as APIt.VisitorAction;
      debug(`addVisitorAction() visitorAction is ${JSON.stringify(visitorAction)}`);
      return(visitorAction);
    }
  } catch(error) {
    console.error(`addVisitorAction() error is ${JSON.stringify(error)}`);
    throw error;
  }

}
addVisitorAction = auditDecorator('addVisitorAction', addVisitorAction);

export let queryBadgeAssignment = async (badgeId: number): Promise<APIt.BadgeAssignment | undefined> => {
  debug(`queryBadgeAssignment() badgeId is ${badgeId}`);

  try {
    const getBadgeAssignmentInput = {
      methodName: 'Welcome_GetBadgeAssignment',
      params: {
        badgeId: badgeId
      }
    };
    debug(`queryBadgeAssignment() getBadgeAssignmentInput is ${JSON.stringify(getBadgeAssignmentInput)}`);
    const getBadgeAssignmentResponse = await API.graphql(graphqlOperation(getBadgeAssignment,
      {
        input: getBadgeAssignmentInput
      })) as GraphQLResult<APIt.GetBadgeAssignmentQuery>;
    debug(`queryBadgeAssignment() getBadgeAssignmentResponse is ${JSON.stringify(getBadgeAssignmentResponse)}`);
    if (getBadgeAssignmentResponse.data && getBadgeAssignmentResponse.data.getBadgeAssignment) {
      const badgeAssignment = getBadgeAssignmentResponse.data.getBadgeAssignment as APIt.BadgeAssignment;
      debug(`queryBadgeAssignment() badgeAssignment is ${JSON.stringify(badgeAssignment)}`);
      return(badgeAssignment);
    }
  } catch(error) {
    console.error(`queryBadgeAssignment() error is ${JSON.stringify(error)}`);
    throw error;
  }
  
}
queryBadgeAssignment = auditDecorator('queryBadgeAssignment', queryBadgeAssignment);

export let queryVisitorRequestForNameAndVendorDayPassBadgeNum = async (firstName: string, lastName: string, vendorDayPassBadgeNum: string): Promise<APIt.VisitorRequest | undefined> => {
  debug(`queryVisitorRequestForNameAndVendorDayPassBadgeNum() firstName is ${firstName} lastName is ${lastName} vendorDayPassBadgeNum is ${vendorDayPassBadgeNum}`);

  try {
    const getVisitorRequestResponse = await API.graphql(graphqlOperation(getVisitorRequestForNameAndVendorDayPassBadgeNum,
      {
        first_name: sqlEscapeString(firstName),
        last_name: sqlEscapeString(lastName),
        vendor_day_pass_badge_num: vendorDayPassBadgeNum,
      })) as GraphQLResult<APIt.GetVisitorRequestForNameAndVendorDayPassBadgeNumQuery>;
    debug(`queryVisitorRequestForNameAndVendorDayPassBadgeNum() getVisitorRequestResponse is ${JSON.stringify(getVisitorRequestResponse)}`);
    if (getVisitorRequestResponse.data && getVisitorRequestResponse.data.getVisitorRequestForNameAndVendorDayPassBadgeNum) {
      const visitorRequest = getVisitorRequestResponse.data.getVisitorRequestForNameAndVendorDayPassBadgeNum as APIt.VisitorRequest;
      debug(`queryVisitorRequestForNameAndVendorDayPassBadgeNum() visitorRequest is ${JSON.stringify(visitorRequest)}`);
      return(visitorRequest);
    }
  } catch(error) {
    console.error(`queryVisitorRequestForNameAndVendorDayPassBadgeNum() error is ${JSON.stringify(error)}`);
    throw error;
  }
  
}
queryVisitorRequestForNameAndVendorDayPassBadgeNum = auditDecorator('queryVisitorRequestForNameAndVendorDayPassBadgeNum', queryVisitorRequestForNameAndVendorDayPassBadgeNum);

export let queryVisitorRequestForVendorDayPassBadgeNum = async (vendorDayPassBadgeNum: string, visitorStatus: VisitorRequestStatus): Promise<APIt.VisitorRequest | undefined> => {
  debug(`queryVisitorRequestForVendorDayPassBadgeNum() vendorDayPassBadgeNum is ${vendorDayPassBadgeNum}`);

  try {
    const getVisitorRequestResponse = await API.graphql(graphqlOperation(getVisitorRequestForVendorDayPassBadgeNum,
      {
        vendor_day_pass_badge_num: vendorDayPassBadgeNum,
        visitor_status: VisitorRequestStatus.CheckedIn,
      })) as GraphQLResult<APIt.GetVisitorRequestForVendorDayPassBadgeNumQuery>;
    debug(`queryVisitorRequestForVendorDayPassBadgeNum() getVisitorRequestResponse is ${JSON.stringify(getVisitorRequestResponse)}`);
    if (getVisitorRequestResponse.data && getVisitorRequestResponse.data.getVisitorRequestForVendorDayPassBadgeNum) {
      const visitorRequest = getVisitorRequestResponse.data.getVisitorRequestForVendorDayPassBadgeNum as APIt.VisitorRequest;
      debug(`queryVisitorRequestForVendorDayPassBadgeNum() visitorRequest is ${JSON.stringify(visitorRequest)}`);
      return(visitorRequest);
    }
  } catch(error) {
    console.error(`queryVisitorRequestForVendorDayPassBadgeNum() error is ${JSON.stringify(error)}`);
    throw error;
  }
  
}
queryVisitorRequestForVendorDayPassBadgeNum = auditDecorator('queryVisitorRequestForVendorDayPassBadgeNum', queryVisitorRequestForVendorDayPassBadgeNum);

export let queryRequestEscorts = async (requestId: string): Promise<APIt.RequestEscort[]> => {
  debug(`queryRequestEscorts() requestId is ${requestId}`);

  let requestEscorts: APIt.RequestEscort[] = [];

  try {
    const response = await API.graphql(graphqlOperation(getRequestEscortsForRequest,
      {
        request_id: requestId,
      })) as GraphQLResult<APIt.GetRequestEscortsForRequestQuery>;
    debug(`queryRequestEscorts() response is ${JSON.stringify(response)}`);
    if (response.data && response.data?.getRequestEscortsForRequest) {
      requestEscorts = response.data?.getRequestEscortsForRequest as APIt.RequestEscort[];
    }
  } catch (error) {
    console.error(`queryRequestEscorts(): exception is ${JSON.stringify(error)}`);
    throw error;
  }

  return requestEscorts;
};
queryRequestEscorts = auditDecorator('queryRequestEscorts', queryRequestEscorts);

export const subscribeOnVisitorUpdate = async(visitorRequest: APIt.VisitorRequest, subscriptionCallback: Function) => {
  debug(`subscribeOnVisitorUpdate() visitorRequest is ${JSON.stringify(visitorRequest)}`);
  const approvedStatusId = await getLookupTypeValueId('Access Level Approval Status', 'Approved');

  const subscription = API.graphql<GraphQLSubscription<APIt.OnVisitorRequestUpdateSubscription>>(
    graphqlOperation(onVisitorRequestUpdate, {
      request_id: visitorRequest.request_id
    })
  ).subscribe({
    next: (response) => {
      debug('Subscription update received:' + JSON.stringify(response));

      const updateData = response.value.data?.onVisitorRequestUpdate;
      if (!updateData) {
        debug('Received empty update data');
        return;
      }

      if (updateData.status_id === approvedStatusId) {
        try {
          debug('Subscription callback triggered');
          subscriptionCallback(updateData.request_id);
        } catch (error) {
          debug('Error processing subscription callback:'+JSON.stringify(error));
        }
        subscription.unsubscribe();
      }
    },
    error: (error) => {
      debug('Subscription error:'+JSON.stringify(error));
      subscription.unsubscribe();
    },
    complete: () => {
      debug('Subscription completed normally');
      subscription.unsubscribe();
    }
  });
  return subscription;

}

export let checkInVisitor = async (visitorRequest: APIt.VisitorRequest, updatedEscorts: IEscort[], updatedBy: string, setStep: Function, setStepProgress: Function): Promise<void> => {
  debug(`checkInVisitor() visitorRequest is ${JSON.stringify(visitorRequest)} updatedEscorts is ${JSON.stringify(updatedEscorts)} updatedBy is ${updatedBy}`);

  const stepPercentage = 16;
  let badgeRequestUUID: string | null | undefined = undefined; 

  if (updatedEscorts.length > 0 && visitorRequest.request_id) {
    const sourceSystem = 'PACS';
    const personSourceSystemId = await getLookupTypeValueId(LookupTypes.PersonSourceSystem, sourceSystem);
    if (!personSourceSystemId) throw `unable to locate requestor source system id for ${sourceSystem}`;
    setStep(CheckInSteps.UpdatingEscorts);
    setStepProgress(0);
    const existingEscorts = await queryRequestEscorts(visitorRequest.request_id);
    for (let updatedEscort of updatedEscorts) {
      const existingEscort = existingEscorts.find(existingEscort => existingEscort.escort_id === updatedEscort.username);
      if (!existingEscort) {
        const createEscortInput: APIt.CreateRequestEscortInput = {
          id: uuid.v4(),
          request_id: visitorRequest.request_id,
          escort_id: updatedEscort.username,
          escort_source_system_id: personSourceSystemId,
          created_by: updatedBy,
        };
        await createEscort(createEscortInput);
      }
    }
    for (let existingEscort of existingEscorts) {
      debug(`checkInVisitor() existingEscort is ${JSON.stringify(existingEscort)}`);
      const updatedEscort = updatedEscorts.find(updatedEscort => updatedEscort.username === existingEscort.escort_id);
      debug(`checkInVisitor() updatedEscort is ${JSON.stringify(updatedEscort)}`);
      if (!updatedEscort) {
        const deleteEscortInput: APIt.DeleteRequestEscortInput = {
          id: existingEscort.id,
          updated_by: updatedBy,
        };
        await deleteEscort(deleteEscortInput);
      }
    }
  }

  try {
    updatedEscorts.length > 0 ? setStepProgress(stepPercentage*.5) : setStepProgress(0);
    if (visitorRequest.visitor_type == VisitorTypes.UnescortedVendor && visitorRequest.vendor_day_pass_badge_num) {
      // check cardholder request status and retry if it failed earlier
      setStep(CheckInSteps.VerifyingCardholder);
      const getVisitorResponse = await API.graphql(graphqlOperation(getVisitor,
        {
          id: visitorRequest.visitor_id
        })) as GraphQLResult<APIt.GetVisitorQuery>;
      debug(`checkInVisitor() getVisitorResponse is ${JSON.stringify(getVisitorResponse)}`);
      const visitor = getVisitorResponse.data?.getVisitor as APIt.Visitor; 
      if (visitor.requestUUID) {
        try {
          const cardholderRequestStatus = await queryAccessControlRequestStatus(visitor.requestUUID);
          debug(`checkInVisitor() cardholderRequestStatus is ${cardholderRequestStatus}`);
          if (cardholderRequestStatus !== 'ACCEPTED_BY_PACS' && cardholderRequestStatus !== 'NO_CHANGE_REQUIRED') {
            const cardholderInput = {
              methodName: 'AddCardholder',
              params: {
                Alias: visitor.person_id,
                Barcode: undefined,
                EmployeeStatus: 'A',
                EmployeeType: 10310,
                FirstName: visitor.first_name!,
                LastName: visitor.last_name!,
                Priority: UnicornPACSAPIv2Priority.HIGH,
                WelcomeID: visitor.id,
              }
            };
            const updateVisitorInput: APIt.UpdateVisitorInput = {
              id: visitor.id,
              updated_by: updatedBy,
            };
            const cardholderResponse = await createCardholder(cardholderInput, updateVisitorInput, updatedBy);
            if (cardholderResponse?.requestUUID) await throttle(() => queryAccessControlRequestStatus(cardholderResponse.requestUUID!), 500);
          }
        } catch(error) {
          console.error(`checkInVisitor(): unable to try to correct potential create cardholder issue, error is ${JSON.stringify(error)}`);
          throw error;
        }
      }

      setStepProgress(stepPercentage*1);
      setStep(CheckInSteps.AssigningBadge);
      await addBadgeAssignment(visitorRequest);
      // get request access levels and assign then to the badge
      setStepProgress(stepPercentage*2);
      setStep(CheckInSteps.AssigningAccessLevels);
      const visitorAccessLevels = await queryVisitorAccessLevelsForVisitor(visitorRequest.visitor_id);
      debug(`checkInVisitor(): visitorAccessLevels is ${JSON.stringify(visitorAccessLevels)}`);
      let accessLevelCount = 0;
      for (let visitorAccessLevel of visitorAccessLevels) {
        if (!visitorAccessLevel.status
        || ![ApprovalStatus.Activated, ApprovalStatus.Approved, ApprovalStatus.Deactivated, ApprovalStatus.Submitted].find(s => s == visitorAccessLevel.status))
          continue;
        // remove existing access level if it exists
        try {
          const removeAccessLevelInput = {
            methodName: 'RemoveAccessLevel',
            params: {
              AccessLevelID: visitorAccessLevel.access_level_id,
              Priority: UnicornPACSAPIv2Priority.HIGH,
              WelcomeID: visitorRequest.visitor_id,
            }
          };
          debug(`checkInVisitor() removeAccessLevelInput is ${JSON.stringify(removeAccessLevelInput)}`);
          const removeAccessLevelResponse = await API.graphql(graphqlOperation(badgeAccessLevel,
            {
              input: removeAccessLevelInput
            })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
          debug(`checkInVisitor() removeAccessLevelResponse is ${JSON.stringify(removeAccessLevelResponse)}`);
          if (removeAccessLevelResponse.data && removeAccessLevelResponse.data.badgeAccessLevel) {
            const badgeAccessLevelRequestResponse = removeAccessLevelResponse.data.badgeAccessLevel as APIt.BadgeAccessLevelRequestResponse;
            debug(`checkInVisitor() badgeAccessLevelRequestResponse for remove is ${JSON.stringify(badgeAccessLevelRequestResponse)}`);
            badgeRequestUUID = badgeAccessLevelRequestResponse?.requestUUID;
            if (badgeRequestUUID) await throttle(() => queryAccessControlRequestStatus(badgeRequestUUID!), 500);
          }
        } catch(error) {
          console.error(`checkInVisitor() error is ${JSON.stringify(error)}`);
          throw error;
        }

        try {
          let hoursExtAccessLevelActivation = 0;
          let hoursExtAccessLevelDeactivation = 0;
          try {
            hoursExtAccessLevelActivation = parseInt(
              (await queryLookupTypeValueForTypeAndDescription(
                LookupTypes.WelcomeApplicationSettings,
                WelcomeApplicationSettings.HoursExtensionAccessLevelActivation)).value);
            hoursExtAccessLevelDeactivation = parseInt(
              (await queryLookupTypeValueForTypeAndDescription(
                LookupTypes.WelcomeApplicationSettings,
                WelcomeApplicationSettings.HoursExtensionAccessLevelDeactivation)).value);
          } catch(error) {
            console.error('Error getting Hours Extension - Access Level... lookup type values', error);
          }
          debug(`checkInVisitor() hoursExtAccessLevelActivation is ${hoursExtAccessLevelActivation}`);
          debug(`checkInVisitor() hoursExtAccessLevelDeactivation is ${hoursExtAccessLevelDeactivation}`);
          const badgeAccessLevelInput = {
            methodName: 'AddAccessLevel',
            params: {
              AccessLevelID: visitorAccessLevel.access_level_id,
	            ActivateTimeEpoch: visitorRequest.start_date
                ? (Date.parse(visitorRequest.start_date+'Z')/1000) - (hoursExtAccessLevelActivation*60*60)
                : undefined,
	            DeactivateTimeEpoch: visitorRequest.end_date
                ? (Date.parse(visitorRequest.end_date+'Z')/1000) + (hoursExtAccessLevelDeactivation*60*60)
                : undefined,
              Priority: UnicornPACSAPIv2Priority.HIGH,
              WelcomeID: visitorRequest.visitor_id,
            }
          };
          debug(`checkInVisitor() badgeAccessLevelInput is ${JSON.stringify(badgeAccessLevelInput)}`);
          const badgeAccessLevelResponse = await API.graphql(graphqlOperation(badgeAccessLevel,
            {
              input: badgeAccessLevelInput
            })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
          debug(`checkInVisitor() badgeAccessLevelResponse is ${JSON.stringify(badgeAccessLevelResponse)}`);
          if (badgeAccessLevelResponse.data?.badgeAccessLevel?.statusCode !== 202) throw new Error('Badge Assignment Failure');
          const updateVisitorAccessLevelInput = {
            id: visitorAccessLevel.id,
            requestUUID: badgeAccessLevelResponse.data.badgeAccessLevel.requestUUID,
            updated_by: updatedBy,
          } as APIt.UpdateVisitorAccessLevelInput;
          const updateVisitorAccessLevelResponse = await updateVisitorAccessLevel(updateVisitorAccessLevelInput);
          debug(`checkInVisitor() updateVisitorAccessLevelResponse is ${JSON.stringify(updateVisitorAccessLevelResponse)}`);
        } catch(error: any) {
          console.error(`checkInVisitor(): AddAccessLevel exception is ${error} ${JSON.stringify(error)}`);
          if (typeof error == 'object' && error && error.errors) throw new Error(`Failed to assign access level: ${error.errors[0].message}`);
          throw new Error(`Failed to assign access level: ${error}`);
        }
        accessLevelCount++;
        setStepProgress((stepPercentage*2) + Math.round(accessLevelCount/visitorAccessLevels.length*stepPercentage));
      }
    }
    const checkedInStatusId = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus, VisitorRequestStatus.CheckedIn);
    const updateVisitorInput = {
      badge_id: visitorRequest.badge_id,
      badge_requestUUID: badgeRequestUUID,
	    id: visitorRequest.visitor_id,
      request_id: visitorRequest.request_id,
      status_id: checkedInStatusId,
	    updated_by: updatedBy,
	    vendor_day_pass_badge_num: visitorRequest.vendor_day_pass_badge_num,
	    visitor_type_id: visitorRequest.visitor_type_id,
    };
    debug(`checkInVisitor() updateVisitorInput is ${JSON.stringify(updateVisitorInput)}`);
    setStepProgress(stepPercentage*3);
    setStep(CheckInSteps.UpdatingVisitor);
    const response = await API.graphql(graphqlOperation(updateVisitor,
      {
        input: updateVisitorInput
      })) as GraphQLResult<APIt.UpdateVisitorMutation>;
    debug(`checkInVisitor() response is ${JSON.stringify(response)}`);

    try {
      setStepProgress(stepPercentage*4);
      setStep(CheckInSteps.RecordingAction);
      await addVisitorAction({
        action_type_id: await getLookupTypeValueId(LookupTypes.Actions, Actions.CheckIn) || '',
        additional_info: JSON.stringify(`{"badge_num": "${visitorRequest.vendor_day_pass_badge_num || visitorRequest.badge_id}"}`),
        created_by: updatedBy,
        id: uuid.v4(),
        visitor_id: visitorRequest.visitor_id,
      });
    } catch(error) {
      console.error(`checkInVisitor() error is ${JSON.stringify(error)}`);
      throw error;
    }

    // send to SNS Topic
    setStepProgress(stepPercentage*5);
    setStep(CheckInSteps.NotifyingOthers);
    try {
      let escortsInput: APIt.EscortInput[] = [];
      if (visitorRequest.request_id) {
        let escorts: APIt.RequestEscort[] = [];
        escorts = await queryRequestEscorts(visitorRequest.request_id);
        for (let escort of escorts) {
          try {
            const empInfo = await queryEmployeeDetails(escort.escort_id);
            escortsInput.push({
              escortName: `${empInfo?.lastName}, ${empInfo?.firstName}`,
              escortEmpId: empInfo?.id,
            });
          } catch(error) {
            console.error(`checkInVisitor() error is ${JSON.stringify(error)}`);
          }
        }
      }
      const visitorReceivedInput: APIt.VisitorReceivedInput = {
	      badge_id: visitorRequest.badge_id,
	      checkInTime: Math.round((new Date).getTime()/1000),
	      company: visitorRequest.company,
        escorts: escortsInput,
	      email: visitorRequest.email,
	      first_name: visitorRequest.first_name,
	      last_name: visitorRequest.last_name,
	      person_id: visitorRequest.person_id,
	      phone_number: visitorRequest.phone_number,
	      request_id: visitorRequest.request_id,
	      site: visitorRequest.site_id,
	      vendor_day_pass_badge_num: visitorRequest.vendor_day_pass_badge_num,
	      visitor_type: (await getLookupTypeValues(LookupTypes.VisitorType)).filter(lv => lv.id == visitorRequest.visitor_type_id)[0].value,
	      visitor_id: visitorRequest.visitor_id,
      }
      debug(`checkInVisitor() visitorReceivedInput is ${JSON.stringify(visitorReceivedInput)}`);
      const response = await API.graphql(graphqlOperation(sNSPublishVisitorReceived,
        {
          visitor: visitorReceivedInput
        })) as GraphQLResult<APIt.SNSPublishVisitorReceivedQuery>;
      if (response && response.data && response.data.SNSPublishVisitorReceived) {
        debug(`checkInVisitor() sNSPublishVisitorReceived response is ${JSON.stringify(response)}`);
      }
    } catch(error) {
      console.error(`checkInVisitor() error is ${JSON.stringify(error)}`);
      throw error;
    }
  } catch (error) {
    console.error(`checkInVisitor(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  setStepProgress(stepPercentage*6);
  setStep(null);
};
checkInVisitor = auditDecorator('checkInVisitor', checkInVisitor);

export let checkOutVisitor = async (
  visitorRequest: APIt.VisitorRequest,
  returning: boolean,
  updatedBy: string,
  setStep: Function,
  setStepProgress: Function): Promise<void> =>
{
  debug(`checkOutVisitor() visitorRequest is ${JSON.stringify(visitorRequest)} returning is ${returning} updatedBy is ${updatedBy}`);

  const stepPercentage = 20;
  let badgeRequestUUID: string | null | undefined = undefined; 

  try {
    // unassign badge to visitor
    if (visitorRequest.vendor_day_pass_badge_num) {
      debug(`checkOutVisitor() setStepProgress(${stepPercentage*0}) setStep(${CheckOutSteps.UnassigningAccessLevels})`);
      setStepProgress(0);
      setStep(CheckOutSteps.UnassigningAccessLevels);
      try {
        const visitorAccessLevels = await queryVisitorAccessLevelsForVisitor(visitorRequest.visitor_id);
        let accessLevelCount = 0;
        for (let visitorAccessLevel of visitorAccessLevels) {
          try {
            const removeAccessLevelInput = {
              methodName: 'RemoveAccessLevel',
              params: {
                AccessLevelID: visitorAccessLevel.access_level_id,
                Priority: UnicornPACSAPIv2Priority.HIGH,
                WelcomeID: visitorRequest.visitor_id,
              }
            };
            debug(`checkOutVisitor() removeAccessLevelInput is ${JSON.stringify(removeAccessLevelInput)}`);
            const removeAccessLevelResponse = await API.graphql(graphqlOperation(badgeAccessLevel,
              {
                input: removeAccessLevelInput
              })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
            debug(`checkOutVisitor() removeAccessLevelResponse is ${JSON.stringify(removeAccessLevelResponse)}`);
            if (removeAccessLevelResponse.data && removeAccessLevelResponse.data.badgeAccessLevel) {
              const badgeAccessLevelRequestResponse = removeAccessLevelResponse.data.badgeAccessLevel as APIt.BadgeAccessLevelRequestResponse;
              debug(`checkOutVisitor() badgeAccessLevelRequestResponse for remove is ${JSON.stringify(badgeAccessLevelRequestResponse)}`);
              const updateVisitorAccessLevelInput = {
                id: visitorAccessLevel.id,
                requestUUID: badgeAccessLevelRequestResponse.requestUUID,
                updated_by: updatedBy,
              } as APIt.UpdateVisitorAccessLevelInput;
              const updateVisitorAccessLevelResponse = await updateVisitorAccessLevel(updateVisitorAccessLevelInput);
              debug(`checkOutVisitor() updateVisitorAccessLevelResponse is ${JSON.stringify(updateVisitorAccessLevelResponse)}`);
              const requestUUID = removeAccessLevelResponse.data.badgeAccessLevel.requestUUID;
              if (requestUUID) await throttle(() => queryAccessControlRequestStatus(requestUUID), 500);
            }
          } catch(error) {
            console.error(`checkOutVisitor() error is ${JSON.stringify(error)}`);
            throw error;
          }
          accessLevelCount++;
          setStepProgress(Math.round(accessLevelCount/visitorAccessLevels.length*stepPercentage));
        }
        const badgeRemovalInput = {
          methodName: 'RemoveBadgeAssignment',
          params: {
            BadgeNumber: parseInt(visitorRequest.vendor_day_pass_badge_num),
            Priority: UnicornPACSAPIv2Priority.HIGH,
            WelcomeID: visitorRequest.visitor_id,
          }
        };
        debug(`checkOutVisitor() badgeRemovalInput is ${JSON.stringify(badgeRemovalInput)}`);
        debug(`checkOutVisitor() setStepProgress(${stepPercentage*1}) setStep(${CheckOutSteps.UnassigningBadge})`);
        setStepProgress(stepPercentage*1);
        setStep(CheckOutSteps.UnassigningBadge);
        const badgeAssignmentResponse = await API.graphql(graphqlOperation(badgeAssignment,
          {
            input: badgeRemovalInput
          })) as GraphQLResult<APIt.BadgeAssignmentMutation>;
        debug(`checkOutVisitor() badgeAssignmentResponse is ${JSON.stringify(badgeAssignmentResponse)}`);
        if (badgeAssignmentResponse.data?.badgeAssignment?.statusCode !== 202) throw new Error('Badge Removal Failure');
        badgeRequestUUID = badgeAssignmentResponse.data.badgeAssignment.requestUUID;
        if (badgeRequestUUID) await throttle(() => queryAccessControlRequestStatus(badgeRequestUUID!), 500);
      } catch(error: any) {
        console.error(`checkOutVisitor(): RemoveBadgeAssignment exception is ${error} JSON: ${JSON.stringify(error)}`);
        if (typeof error == 'object' && error && error.errors) throw new Error(`Failed to remove badge: ${error.errors[0].message}`);
        throw new Error(`Failed to assign badge: ${error}`);
      }
    }

    debug(`checkOutVisitor() setStepProgress(${stepPercentage*2}) setStep(${CheckOutSteps.UpdatingVisitor})`);
    setStepProgress(stepPercentage*2);
    setStep(CheckOutSteps.UpdatingVisitor);
    const checkedOutStatusId = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus, VisitorRequestStatus.CheckedOut);
    const scheduledVisitStatusId = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus, VisitorRequestStatus.ScheduledVisit);
    const updateVisitorInput = {
      badge_id: 'unassigned',
      badge_requestUUID: badgeRequestUUID,
	    id: visitorRequest.visitor_id,
      status_id: returning ? scheduledVisitStatusId : checkedOutStatusId,
	    vendor_day_pass_badge_num: 'unassigned',
	    visitor_pass_assignment_id: null,
	    updated_by: updatedBy,
    };
    debug(`checkOutVisitor() updateVisitorInput is ${JSON.stringify(updateVisitorInput)}`);
    const response = await API.graphql(graphqlOperation(updateVisitor,
      {
        input: updateVisitorInput
      })) as GraphQLResult<APIt.UpdateVisitorMutation>;
    debug(`checkOutVisitor() response is ${JSON.stringify(response)}`);

    try {
      debug(`checkOutVisitor() setStepProgress(${stepPercentage*3}) setStep(${CheckOutSteps.RecordingAction})`);
      setStepProgress(stepPercentage*3);
      setStep(CheckOutSteps.RecordingAction);
      await addVisitorAction({
        action_type_id: await getLookupTypeValueId(LookupTypes.Actions, Actions.CheckOut) || '',
        additional_info: JSON.stringify(`{"badge_num": "${visitorRequest.vendor_day_pass_badge_num || visitorRequest.badge_id}"}`),
        created_by: updatedBy,
        id: uuid.v4(),
        visitor_id: visitorRequest.visitor_id,
      });
    } catch(error) {
      console.error(`checkOutVisitor() error is ${JSON.stringify(error)}`);
      throw error;
    }

    // send to SNS Topic
    if (visitorRequest.request_id) {
      try {
        debug(`checkOutVisitor() setStepProgress(${stepPercentage*4}) setStep(${CheckOutSteps.NotifyingOthers})`);
        setStepProgress(stepPercentage*4);
        setStep(CheckOutSteps.NotifyingOthers);
        let escortsInput: APIt.EscortInput[] = [];
        if (visitorRequest.request_id) {
          let escorts: APIt.RequestEscort[] = [];
          escorts = await queryRequestEscorts(visitorRequest.request_id);
          for (let escort of escorts) {
            try {
              const empInfo = await queryEmployeeDetails(escort.escort_id);
              escortsInput.push({
                escortName: `${empInfo?.lastName}, ${empInfo?.firstName}`,
                escortEmpId: empInfo?.id,
              });
            } catch(error) {
              console.error(`checkOutVisitor() error is ${JSON.stringify(error)}`);
            }
          }
        }
        const visitorDepartedInput: APIt.VisitorDepartedInput = {
	        badge_id: visitorRequest.badge_id,
	        checkOutTime: Math.round((new Date).getTime()/1000),
	        company: visitorRequest.company,
          escorts: escortsInput,
	        email: visitorRequest.email,
	        first_name: visitorRequest.first_name,
	        last_name: visitorRequest.last_name,
	        person_id: visitorRequest.person_id,
	        phone_number: visitorRequest.phone_number,
	        request_id: visitorRequest.request_id,
	        site: visitorRequest.site_id,
	        vendor_day_pass_badge_num: visitorRequest.vendor_day_pass_badge_num,
	        visitor_type: (await getLookupTypeValues(LookupTypes.VisitorType)).filter(lv => lv.id == visitorRequest.visitor_type_id)[0].value,
	        visitor_id: visitorRequest.visitor_id,
        }
        debug(`checkOutVisitor() visitorDepartedInput is ${JSON.stringify(visitorDepartedInput)}`);
        const response = await API.graphql(graphqlOperation(sNSPublishVisitorDeparted,
          {
            visitor: visitorDepartedInput
          })) as GraphQLResult<APIt.SNSPublishVisitorDepartedQuery>;
        if (response && response.data && response.data.SNSPublishVisitorDeparted) {
          debug(`checkOutVisitor() sNSPublishVisitorDeparted response is ${JSON.stringify(response)}`);
        }
      } catch(error) {
        console.error(`checkOutVisitor() error is ${JSON.stringify(error)}`);
        throw error;
      }
    }
  } catch (error) {
    console.error(`checkOutVisitor() exception is ${JSON.stringify(error)}`);
    throw error;
  }
  debug(`checkOutVisitor() setStepProgress(${stepPercentage*5}) setStep(null)`);
  setStepProgress(stepPercentage*5);
  setStep(null);
};
checkOutVisitor = auditDecorator('checkOutVisitor', checkOutVisitor);

export let handleNonWelcomeVisitor = async (
  createVisitorInput: APIt.CreateVisitorInput,
  empid: number,
  setStep: Function,
  setStepProgress: Function,
  ): Promise<APIt.Visitor> =>
{
  debug(`handleNonWelcomeVisitor() createVisitorInput is ${JSON.stringify(createVisitorInput)}`);

  let createdVisitor: APIt.Visitor | null = null;

  setStep(CheckInSteps.HandlingNonWelcomeVisitor);
  try {
    try {
      if (createVisitorInput.first_name) createVisitorInput.first_name = sqlEscapeString(createVisitorInput.first_name);
      if (createVisitorInput.last_name) createVisitorInput.last_name = sqlEscapeString(createVisitorInput.last_name);
      if (createVisitorInput.company) createVisitorInput.company = sqlEscapeString(createVisitorInput.company);
      if (createVisitorInput.email) createVisitorInput.email = sqlEscapeString(createVisitorInput.email);
      if (createVisitorInput.phone_number) createVisitorInput.phone_number = sqlEscapeString(createVisitorInput.phone_number);
      if (createVisitorInput.person_id) createVisitorInput.person_id = sqlEscapeString(createVisitorInput.person_id);
      if (createVisitorInput.title) createVisitorInput.title = sqlEscapeString(createVisitorInput.title);
      debug(`handleNonWelcomeVisitor() createVisitorInput is ${JSON.stringify(createVisitorInput)}`);
      const response = await API.graphql(graphqlOperation(createVisitor,
        {
          input: createVisitorInput
        })) as GraphQLResult<APIt.CreateVisitorMutation>;
      if (response && response.data && response.data.createVisitor) {
        createdVisitor = response.data.createVisitor;
      }
      if (!createdVisitor) throw new Error('Unable to create visitor.');
    } catch(error) {
      debug(`handleNonWelcomeVisitor() exception is ${JSON.stringify(error)}`);
      console.error(error);
      throw error;
    }
    if (!createdVisitor) throw new Error('Error When Creating Welcome Visitor for Current Badge Holder. Unable to Create Visitor.');
    const updateCardholderInput = {
      methodName: 'UpdateCardholder',
      params: {
        Alias: createdVisitor.person_id !== null ? createdVisitor.person_id : '',
        EmployeeStatus: 'A',
        EmployeeType: 10310,
        FirstName: createdVisitor.first_name,
        LastName: createdVisitor.last_name,
        OnGuardCardholderID: empid,
        Priority: UnicornPACSAPIv2Priority.HIGH,
        WelcomeID: createdVisitor.id,
      }};
    debug(`handleNonWelcomeVisitor() updateCardholderInput is ${JSON.stringify(updateCardholderInput)}`);
    const updateCardholderResponse = await API.graphql(graphqlOperation(cardholder,
      {
        input: updateCardholderInput,
      })) as GraphQLResult<APIt.CardholderMutation>;
    debug(`handleNonWelcomeVisitor() updateCardholderResponse is ${JSON.stringify(updateCardholderResponse)}`);
    debug(`handleNonWelcomeVisitor() updateCardholderResponse.data.cardholder is ${JSON.stringify(updateCardholderResponse?.data?.cardholder)}`);
    if (!updateCardholderResponse?.data?.cardholder?.requestUUID) throw new Error('Unable to Create EMP Link for Non-Welcome Visitor');
    const requestUUID = updateCardholderResponse.data.cardholder.requestUUID;
    if (requestUUID) await throttle(() => queryAccessControlRequestStatus(requestUUID!), 500);
    const updateVisitorInput = {
      id: createdVisitor.id,
      requestUUID: updateCardholderResponse.data.cardholder.requestUUID,
      updated_by: createVisitorInput.created_by,
    };
    debug(`handleNonWelcomeVisitor() updateVisitorInput is ${JSON.stringify(updateVisitorInput)}`);
    let updatedVisitor;
    const response = await API.graphql(graphqlOperation(updateVisitor,
      {
        input: updateVisitorInput
      })) as GraphQLResult<APIt.UpdateVisitorMutation>;
    if (response && response.data && response.data.updateVisitor) {
      updatedVisitor = response.data.updateVisitor;
    }
    if (!updatedVisitor) throw new Error('Unable to update visitor.');
    setStepProgress(1);
    return(createdVisitor);
  } catch(error) {
    console.error(`handleNonWelcomeVisitor() error is ${JSON.stringify(error)}`);
    throw error;
  }
}
handleNonWelcomeVisitor = auditDecorator('handleNonWelcomeVisitor', handleNonWelcomeVisitor);

export let queryAccessControlRequestStatus = async (requestUUID: string): Promise<string | undefined> => {
  debug(`queryAccessControlRequestStatus() requestUUID is ${requestUUID}`);

  if (!requestUUID) return;

  let status: string | undefined = undefined, attempts = 0;
  const MAX_ATTEMPTS = 30;

  do {
    try {
      const response = await API.graphql(graphqlOperation(accessControlRequestStatus,
        {
          input: {
            methodName: 'RequestStatus',
            params: {
	            RequestUUID: requestUUID,
            },
          }
        })) as GraphQLResult<APIt.AccessControlRequestStatusMutation>;
      debug(`queryAccessControlRequestStatus() response is ${JSON.stringify(response)}`);
      if (response.data && response.data?.accessControlRequestStatus) {
        const statusMessage = (response.data?.accessControlRequestStatus as APIt.AccessControlRequestStatusResponse)?.message;
        if (statusMessage) status = JSON.parse(statusMessage).RequestStatus;
        debug(`queryAccessControlRequestStatus() status is ${status}`);
      }
    } catch (error) {
      console.error(`queryAccessControlRequestStatus(): exception is ${JSON.stringify(error)}`);
      throw error;
    }
    attempts++;
    debug(`queryAccessControlRequestStatus() attempts is ${attempts}`);
  } while ((status == undefined || !['ACCEPTED_BY_PACS','FAILED','NO_CHANGE_REQUIRED'].includes(status)) && attempts <= MAX_ATTEMPTS);

  return status;
};
queryAccessControlRequestStatus = auditDecorator('queryAccessControlRequestStatus', queryAccessControlRequestStatus);

export let queryVisitorAccessLevelsForVisitor = async (visitorId: string): Promise<APIt.VisitorAccessLevel[]> => {
  debug(`queryVisitAccessLevelsForVisitor() visitorId is ${visitorId}`);

  let visitorAccessLevels: APIt.VisitorAccessLevel[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAccessLevelsForVisitor,
      {
        visitor_id: visitorId,
      })) as GraphQLResult<APIt.ListVisitorAccessLevelsForVisitorQuery>;
    debug(`queryVisitAccessLevelsForVisitor() response is ${JSON.stringify(response)}`);
    if (response.data && response.data?.listVisitorAccessLevelsForVisitor) {
      visitorAccessLevels = response.data?.listVisitorAccessLevelsForVisitor as APIt.VisitorAccessLevel[];
    }
  } catch (error) {
    console.error(`queryVisitAccessLevelsForVisitor(): exception is ${JSON.stringify(error)}`);
    throw error;
  }

  return visitorAccessLevels;
};
queryVisitorAccessLevelsForVisitor = auditDecorator('queryVisitorAccessLevelsForVisitor', queryVisitorAccessLevelsForVisitor);

export let queryVisitorActions = async (visitorId: string): Promise<APIt.VisitorAction[]> => {
  debug(`queryVisitorActions() visitorId is ${visitorId}`);

  let visitorActions: APIt.VisitorAction[] = [];

  try {
    const response = await API.graphql(graphqlOperation(listVisitorActions,
      {
        visitor_id: visitorId,
      })) as GraphQLResult<APIt.ListVisitorActionsQuery>;
    debug(`queryVisitorActions() response is ${JSON.stringify(response)}`);
    if (response.data && response.data?.listVisitorActions) {
      visitorActions = response.data?.listVisitorActions as APIt.VisitorAction[];
    }
  } catch (error) {
    console.error(`queryVisitorActions(): exception is ${JSON.stringify(error)}`);
    throw error;
  }

  return visitorActions;
};
queryVisitorActions = auditDecorator('queryVisitorActions', queryVisitorActions);

export let reapplyAccessLevel = async (visitorRequest: APIt.VisitorRequest, visitorAccessLevel: APIt.VisitorAccessLevel): Promise<void> => {
  debug(`reapplyAccessLevel() visitorAccessLevel is ${JSON.stringify(visitorAccessLevel)}`);

  let badgeAccessLevelRequestResponse: APIt.BadgeAccessLevelRequestResponse | null = null;

  if (visitorAccessLevel.visitor_type == VisitorTypes.UnescortedVendor) {
    // create badge assignment
    if (visitorRequest.vendor_day_pass_badge_num && visitorRequest.vendor_day_pass_badge_num !== 'unassigned')
      await addBadgeAssignment(visitorRequest);

    // remove existing access level if it exists
    try {
      const removeAccessLevelInput = {
        methodName: 'RemoveAccessLevel',
        params: {
          AccessLevelID: visitorAccessLevel.access_level_id,
          Priority: UnicornPACSAPIv2Priority.HIGH,
          WelcomeID: visitorAccessLevel.visitor_id,
        }
      };
      debug(`reapplyAccessLevel() removeAccessLevelInput is ${JSON.stringify(removeAccessLevelInput)}`);
      const removeAccessLevelResponse = await API.graphql(graphqlOperation(badgeAccessLevel,
        {
          input: removeAccessLevelInput
        })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
      debug(`reapplyAccessLevel() removeAccessLevelResponse is ${JSON.stringify(removeAccessLevelResponse)}`);
      if (removeAccessLevelResponse.data && removeAccessLevelResponse.data.badgeAccessLevel) {
        badgeAccessLevelRequestResponse = removeAccessLevelResponse.data.badgeAccessLevel as APIt.BadgeAccessLevelRequestResponse;
      }
      debug(`reapplyAccessLevel() badgeAccessLevelRequestResponse for remove is ${JSON.stringify(badgeAccessLevelRequestResponse)}`);
    } catch(error) {
      console.error(`reapplyAccessLevel() error is ${JSON.stringify(error)}`);
      throw error;
    }

    // add access level
    try {
      const badgeAccessLevelInput = {
        methodName: 'AddAccessLevel',
        params: {
          AccessLevelID: visitorAccessLevel.access_level_id,
          ActivateTimeEpoch: Date.parse(visitorAccessLevel.start_date+'Z')/1000,
          DeactivateTimeEpoch: Date.parse(visitorAccessLevel.end_date+'Z')/1000,
          Priority: UnicornPACSAPIv2Priority.HIGH,
          WelcomeID: visitorAccessLevel.visitor_id,
        }
      };
      debug(`reapplyAccessLevel() badgeAccessLevelInput is ${JSON.stringify(badgeAccessLevelInput)}`);
      const response = await API.graphql(graphqlOperation(badgeAccessLevel,
        {
          input: badgeAccessLevelInput
        })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
      debug(`reapplyAccessLevel() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.badgeAccessLevel) {
        badgeAccessLevelRequestResponse = response.data.badgeAccessLevel as APIt.BadgeAccessLevelRequestResponse;
      }
      debug(`reapplyAccessLevel() badgeAccessLevelRequestResponse for add is ${JSON.stringify(badgeAccessLevelRequestResponse)}`);
      const updateVisitorAccessLevelInput = {
        id: visitorAccessLevel.id,
        requestUUID: badgeAccessLevelRequestResponse?.requestUUID,
        updated_by: visitorAccessLevel.updated_by,
      } as APIt.UpdateVisitorAccessLevelInput;
      const updateVisitorAccessLevelResponse = await updateVisitorAccessLevel(updateVisitorAccessLevelInput);
      debug(`reapplyAccessLevel() updateVisitorAccessLevelResponse is ${JSON.stringify(updateVisitorAccessLevelResponse)}`);
    } catch(error) {
      console.error(`reapplyAccessLevel() error is ${JSON.stringify(error)}`);
      throw error;
    }
  } else if (visitorAccessLevel.visitor_type == VisitorTypes.Employee) {
    let personIdHash;

    if (!visitorAccessLevel.person_id) throw new Error('missing person_id');

    try {
      personIdHash = (await queryEmployeeDetails(visitorAccessLevel.person_id))?.idHash;
    } catch(error) {
      console.error(`reapplyAccessLevel() cannot get employee details for ${visitorAccessLevel.person_id} error is ${error}`);
    }

    // remove existing access level if it exists
    try {
      const removeAccessLevelInput = {
        methodName: 'RemoveAccessLevel',
        params: {
          AccessLevelID: visitorAccessLevel.access_level_id,
          Priority: UnicornPACSAPIv2Priority.HIGH,
          PersonID: personIdHash,
        }
      };
      debug(`reapplyAccessLevel() removeAccessLevelInput is ${JSON.stringify(removeAccessLevelInput)}`);
      const removeAccessLevelResponse = await API.graphql(graphqlOperation(badgeAccessLevel,
        {
          input: removeAccessLevelInput
        })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
      debug(`reapplyAccessLevel() removeAccessLevelResponse is ${JSON.stringify(removeAccessLevelResponse)}`);
      if (removeAccessLevelResponse.data && removeAccessLevelResponse.data.badgeAccessLevel) {
        badgeAccessLevelRequestResponse = removeAccessLevelResponse.data.badgeAccessLevel as APIt.BadgeAccessLevelRequestResponse;
        if (badgeAccessLevelRequestResponse?.requestUUID) await throttle(() => queryAccessControlRequestStatus(badgeAccessLevelRequestResponse!.requestUUID!), 500);
      }
      debug(`reapplyAccessLevel() badgeAccessLevelRequestResponse for remove is ${JSON.stringify(badgeAccessLevelRequestResponse)}`);
    } catch(error) {
      console.error(`reapplyAccessLevel() error is ${JSON.stringify(error)}`);
      throw error;
    }

    // add access level
    try {
      const badgeAccessLevelInput = {
        methodName: 'AddAccessLevel',
        params: {
          AccessLevelID: visitorAccessLevel.access_level_id,
          ActivateTimeEpoch: Date.parse(visitorAccessLevel.start_date+'Z')/1000,
          DeactivateTimeEpoch: Date.parse(visitorAccessLevel.end_date+'Z')/1000,
          Priority: UnicornPACSAPIv2Priority.HIGH,
          PersonID: personIdHash,
        }
      };
      debug(`reapplyAccessLevel() badgeAccessLevelInput is ${JSON.stringify(badgeAccessLevelInput)}`);
      const response = await API.graphql(graphqlOperation(badgeAccessLevel,
        {
          input: badgeAccessLevelInput
        })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
      debug(`reapplyAccessLevel() response is ${JSON.stringify(response)}`);
      if (response.data && response.data.badgeAccessLevel) {
        badgeAccessLevelRequestResponse = response.data.badgeAccessLevel as APIt.BadgeAccessLevelRequestResponse;
      }
      debug(`reapplyAccessLevel() badgeAccessLevelRequestResponse for add is ${JSON.stringify(badgeAccessLevelRequestResponse)}`);
      const updateVisitorAccessLevelInput = {
        id: visitorAccessLevel.id,
        requestUUID: badgeAccessLevelRequestResponse?.requestUUID,
        updated_by: visitorAccessLevel.updated_by,
      } as APIt.UpdateVisitorAccessLevelInput;
      const updateVisitorAccessLevelResponse = await updateVisitorAccessLevel(updateVisitorAccessLevelInput);
      debug(`reapplyAccessLevel() updateVisitorAccessLevelResponse is ${JSON.stringify(updateVisitorAccessLevelResponse)}`);
    } catch(error) {
      console.error(`reapplyAccessLevel() error is ${JSON.stringify(error)}`);
      throw error;
    }
  }

};
reapplyAccessLevel = auditDecorator('reapplyAccessLevel', reapplyAccessLevel);