import { API, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import * as APIt from "src/API";
import {
  getVisitor,
  getVisitorAccessLevel,
  getVisitorAccessLevelApproval as getVisitorAccessLevelApprovalForId,
  listVisitorAccessLevelApprovalsForVisitorAccessLevel,
  listVisitorAccessLevelsForRequestAndVisitor,
  listVisitorAccessLevelsForRequestor,
  sNSPublishAccessLevelRequestApproved,
  sNSPublishAccessLevelRequestDeclined,
} from 'src/graphql/queries';
import {
  badgeAccessLevel as badgeAccessLevelMutation,
  deleteVisitorAccessLevel as deleteVisitorAccessLevelMutation,
  updateVisitorAccessLevel as updateVisitorAccessLevelMutation,
  updateVisitorAccessLevelApproval as updateVisitorAccessLevelApprovalMutation,
  updateVisitor as updateVisitorMutation,
  badgeAccessLevel,
} from 'src/graphql/mutations';
import {
  auditDecorator,
  getLookupTypeValueId,
  getLookupTypeValues,
  queryEmployeeDetails,
  queryLookupTypeValueForTypeAndDescription,
  queryLookupTypeValues,
  sqlEscapeString,
  throttle
} from 'src/components/utils';
import {
  ApprovalStatus,
  LookupTypes,
  UnicornPACSAPIv2Priority,
  VisitorRequestStatus,
  VisitorTypes,
  WelcomeApplicationSettings,
} from 'src/constants/Constants';
import { IVisitorAccessLevelWithAction } from './TablePanel';
import { queryAccessControlRequestStatus } from '../ManageVisitors/utils';
import { debug } from 'src/utils/commonUtils';

export let queryDenialReasons = async (): Promise<APIt.LookupTypeValue[]> => {
  debug('queryDenialReasons()');

  let denialReasons: APIt.LookupTypeValue[] = [];

  try {
    denialReasons = await getLookupTypeValues(LookupTypes.AccessLevelDenial);
    debug(`queryDenialReasons() denialReasons is ${JSON.stringify(denialReasons)}`);
  } catch(error) {
    console.error(`queryDenialReasons() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return denialReasons;
};
queryDenialReasons = auditDecorator('queryDenialReasons', queryDenialReasons);

export let queryPendingVisitorAccessLevels = async (personId: string | undefined, requestorId: string | undefined): Promise<APIt.VisitorAccessLevel[] | null> => {
  debug(`queryPendingVisitorAccessLevels() requestorId is ${requestorId}`);

  if (!requestorId || !personId) return null;

  let pendingVisitorAccessLevelsForRequestor: APIt.VisitorAccessLevel[] = [];
  let requestorSourceSystemId;
  let personSourceSystemId;

  try {
    requestorSourceSystemId = await getLookupTypeValueId('Requestor Source System','PACS');
    debug(`queryPendingVisitorAccessLevels() requestorSourceSystemId is ${requestorSourceSystemId}`);
    personSourceSystemId = await getLookupTypeValueId('Person Source System','PACS');
    debug(`queryPendingVisitorAccessLevels() personSourceSystemId is ${personSourceSystemId}`);
  } catch(error) {
    console.error(`queryPendingVisitorAccessLevels() error is ${JSON.stringify(error)}`);
    throw error;
  }

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAccessLevelsForRequestor,
      {
        person_id: personId,
        person_source_system_id: personSourceSystemId,
        requestor_id: requestorId,
        requestor_source_system_id: requestorSourceSystemId,
        status: ApprovalStatus.PendingApproval,
      })) as GraphQLResult<APIt.ListVisitorAccessLevelsForRequestorQuery>;
    if (response.data && response.data.listVisitorAccessLevelsForRequestor) {
      pendingVisitorAccessLevelsForRequestor = response.data.listVisitorAccessLevelsForRequestor as APIt.VisitorAccessLevel[];
    }
  } catch(error) {
    console.error(`queryPendingVisitorAccessLevels(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(pendingVisitorAccessLevelsForRequestor);
};
queryPendingVisitorAccessLevels = auditDecorator('queryPendingVisitorAccessLevels', queryPendingVisitorAccessLevels);

export let queryVisitorAccessLevelApprovals = async (visitorAccessLevelId: string | undefined): Promise<APIt.VisitorAccessLevelApproval[]> => {
  debug(`queryVisitorAccessLevelApprovals() visitorAccessLevelId is ${visitorAccessLevelId}`);

  let visitorAccessLevelApprovals: APIt.VisitorAccessLevelApproval[] = [];

  if (!visitorAccessLevelId) return visitorAccessLevelApprovals;

  try {
    const response = await API.graphql(graphqlOperation(listVisitorAccessLevelApprovalsForVisitorAccessLevel,
      {
        visitor_access_level_id: visitorAccessLevelId
      })) as GraphQLResult<APIt.ListVisitorAccessLevelApprovalsForVisitorAccessLevelQuery>;
    debug(`queryVisitorAccessLevelApprovals() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.listVisitorAccessLevelApprovalsForVisitorAccessLevel) {
      visitorAccessLevelApprovals = response.data.listVisitorAccessLevelApprovalsForVisitorAccessLevel as APIt.VisitorAccessLevelApproval[];
    }
  } catch(error) {
    console.error(`queryVisitorAccessLevelApprovals(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`queryVisitorAccessLevelApprovals() visitorAccessLevelApprovals is ${JSON.stringify(visitorAccessLevelApprovals)}`);
  return(visitorAccessLevelApprovals);
};
queryVisitorAccessLevelApprovals = auditDecorator('queryVisitorAccessLevelApprovals', queryVisitorAccessLevelApprovals);

export let submitAddAccessLevel = 
  async (
    personId: string,
    accessLevelId: string,
    permanent: boolean,
    startTimeEpoch: number | null | undefined,
    endTimeEpoch: number | null | undefined): Promise<APIt.BadgeAccessLevelRequestResponse | null> =>
 {
  debug(`submitAddAccessLevel() personId is ${personId} accessLevelId is ${accessLevelId} permanent is ${permanent} startEpochTime is ${startTimeEpoch} endTimeEpoch is ${endTimeEpoch}`);

  let badgeAccessLevelRequestResponse: APIt.BadgeAccessLevelRequestResponse | null = null;

  if ((!personId || !accessLevelId) || (!permanent && (!startTimeEpoch || !endTimeEpoch))) return badgeAccessLevelRequestResponse;

  let personIdHash;

  try {
    personIdHash = (await queryEmployeeDetails(personId))?.idHash;
    debug(`submitAddAccessLevel() personIdHash is ${personIdHash}`);
  } catch(error) {
    // may be a visitor or vendor
    debug(`submitAddAccessLevel() cannot get employee details for ${personId} error is ${error}`);
  }

  // remove existing access level if it exists
  try {
    const removeAccessLevelInput = {
      methodName: 'RemoveAccessLevel',
      params: {
        AccessLevelID: accessLevelId,
        PersonID: personIdHash,
        Priority: UnicornPACSAPIv2Priority.HIGH,
      }
    };
    debug(`submitAddAccessLevel() removeAccessLevelInput is ${JSON.stringify(removeAccessLevelInput)}`);
    const removeAccessLevelResponse = await API.graphql(graphqlOperation(badgeAccessLevelMutation,
      {
        input: removeAccessLevelInput
      })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
    debug(`submitAddAccessLevel() removeAccessLevelResponse is ${JSON.stringify(removeAccessLevelResponse)}`);
    if (removeAccessLevelResponse.data && removeAccessLevelResponse.data.badgeAccessLevel) {
      badgeAccessLevelRequestResponse = removeAccessLevelResponse.data.badgeAccessLevel as APIt.BadgeAccessLevelRequestResponse;
    }
    debug(`submitAddAccessLevel() badgeAccessLevelRequestResponse for remove is ${JSON.stringify(badgeAccessLevelRequestResponse)}`);
    if (badgeAccessLevelRequestResponse?.requestUUID) await throttle(() => queryAccessControlRequestStatus(badgeAccessLevelRequestResponse!.requestUUID!), 500);
  } catch(error) {
    console.error(`submitAddAccessLevel() error is ${JSON.stringify(error)}`);
    throw error;
  }

  // add access level
  try {
    const badgeAccessLevelInput = {
      methodName: 'AddAccessLevel',
      params: {
        AccessLevelID: accessLevelId,
        ActivateTimeEpoch: startTimeEpoch,
        DeactivateTimeEpoch: endTimeEpoch,
        PersonID: personIdHash,
        Priority: UnicornPACSAPIv2Priority.HIGH,
      }
    };
    debug(`submitAddAccessLevel() badgeAccessLevelInput is ${JSON.stringify(badgeAccessLevelInput)}`);
    const response = await API.graphql(graphqlOperation(badgeAccessLevelMutation,
      {
        input: badgeAccessLevelInput
      })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
    debug(`submitAddAccessLevel() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.badgeAccessLevel) {
      badgeAccessLevelRequestResponse = response.data.badgeAccessLevel as APIt.BadgeAccessLevelRequestResponse;
    }
  } catch(error) {
    console.error(`submitAddAccessLevel() error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`submitAddAccessLevel() badgeAccessLevelRequestResponse is ${JSON.stringify(badgeAccessLevelRequestResponse)}`);
  return(badgeAccessLevelRequestResponse);
};
submitAddAccessLevel = auditDecorator('submitAddAccessLevel', submitAddAccessLevel);

export let queryVisitorAccessLevel = async (visitorAccessLevelId: string): Promise<APIt.VisitorAccessLevel | null> => {
  debug(`queryVisitorAccessLevel() visitorAccessLevelId is ${visitorAccessLevelId}`);

  let visitorAccessLevel: APIt.VisitorAccessLevel | null = null;

  if (!visitorAccessLevelId) return visitorAccessLevel;

  try {
    const response = await API.graphql(graphqlOperation(getVisitorAccessLevel,
      {
        id: visitorAccessLevelId
      })) as GraphQLResult<APIt.GetVisitorAccessLevelQuery>;
    debug(`queryVisitorAccessLevel() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.getVisitorAccessLevel) {
      visitorAccessLevel = response.data.getVisitorAccessLevel as APIt.VisitorAccessLevel;
    }
  } catch(error) {
    console.error(`queryVisitorAccessLevel(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`queryVisitorAccessLevel() visitorAccessLevel is ${JSON.stringify(visitorAccessLevel)}`);
  return(visitorAccessLevel);
};
queryVisitorAccessLevel = auditDecorator('queryVisitorAccessLevel', queryVisitorAccessLevel);

export let queryVisitorAccessLevelApproval = async (visitorAccessLevelApprovalId: string): Promise<APIt.VisitorAccessLevelApproval | null> => {
  debug(`queryVisitorAccessLevelApproval() visitorAccessLevelApprovalId is ${visitorAccessLevelApprovalId}`);

  let visitorAccessLevelApproval: APIt.VisitorAccessLevelApproval | null = null;

  if (!visitorAccessLevelApprovalId) return visitorAccessLevelApproval;

  try {
    const response = await API.graphql(graphqlOperation(getVisitorAccessLevelApprovalForId,
      {
        id: visitorAccessLevelApprovalId
      })) as GraphQLResult<APIt.GetVisitorAccessLevelApprovalQuery>;
    debug(`queryVisitorAccessLevelApproval() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.getVisitorAccessLevelApproval) {
      visitorAccessLevelApproval = response.data.getVisitorAccessLevelApproval as APIt.VisitorAccessLevelApproval;
    }
  } catch(error) {
    console.error(`queryVisitorAccessLevelApproval(): error is ${error} JSON: ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`queryVisitorAccessLevelApproval() visitorAccessLevelApproval is ${JSON.stringify(visitorAccessLevelApproval)}`);
  return(visitorAccessLevelApproval);
};
queryVisitorAccessLevelApproval = auditDecorator('queryVisitorAccessLevelApproval', queryVisitorAccessLevelApproval);

export let getVisitorAccessLevelApproval = async (visitorAccessLevelId: string, approverId: string): Promise<APIt.VisitorAccessLevelApproval | null> => {
  debug(`getVisitorAccessLevelApproval() visitorAccessLevelId is ${visitorAccessLevelId} approverId is ${approverId}`);

  let visitorAccessLevelApproval: APIt.VisitorAccessLevelApproval | null = null;
  let cancelledStatusCodeId = await getLookupTypeValueId('Access Level Approval Status', 'Cancelled');
  debug(`getVisitorAccessLevelApproval() cancelledStatusCodeId is ${cancelledStatusCodeId}`);

  if (!visitorAccessLevelId || !approverId) return visitorAccessLevelApproval;

  try {
    const visitorAccessLevelApprovals = await queryVisitorAccessLevelApprovals(visitorAccessLevelId);
    debug(`getVisitorAccessLevelApproval() visitorAccessLevelApprovals is ${JSON.stringify(visitorAccessLevelApprovals)}`);
    visitorAccessLevelApproval = visitorAccessLevelApprovals.filter(ala => ala.approver_id == approverId)[0];
  } catch (error) {
    console.error(`getVisitorAccessLevelApproval() error is ${error} JSON: ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`getVisitorAccessLevelApproval() visitorAccessLevelApproval is ${JSON.stringify(visitorAccessLevelApproval)}`);
  return(visitorAccessLevelApproval);
};
getVisitorAccessLevelApproval = auditDecorator('getVisitorAccessLevelApproval', getVisitorAccessLevelApproval);

export let updateVisitorAccessLevel = async (updateVisitorAccessLevelInput: APIt.UpdateVisitorAccessLevelInput): Promise<APIt.VisitorAccessLevel | null> => {
  debug(`updateVisitorAccessLevel() updateVisitorAccessLevelInput is ${JSON.stringify(updateVisitorAccessLevelInput)}`);

  let visitorAccessLevel: APIt.VisitorAccessLevel | null = null;

  if (!updateVisitorAccessLevelInput) return visitorAccessLevel;

  try {
    const response = await API.graphql(graphqlOperation(updateVisitorAccessLevelMutation,
      {
        input: updateVisitorAccessLevelInput,
      })) as GraphQLResult<APIt.UpdateVisitorAccessLevelMutation>;
    debug(`updateVisitorAccessLevel() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.updateVisitorAccessLevel) {
      visitorAccessLevel = response.data.updateVisitorAccessLevel as APIt.VisitorAccessLevel;
    }
  } catch (error) {
    console.error(`updateVisitorAccessLevel(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`updateVisitorAccessLevel() visitorAccessLevel is ${JSON.stringify(visitorAccessLevel)}`);
  return(visitorAccessLevel);
};
updateVisitorAccessLevel = auditDecorator('updateVisitorAccessLevel', updateVisitorAccessLevel);

export let updateVisitor = async (updateVisitorInput: APIt.UpdateVisitorInput): Promise<APIt.Visitor | null> => {
  debug(`updateVisitor() updateVisitorInput is ${JSON.stringify(updateVisitorInput)}`);

  let updatedVisitor: APIt.Visitor | null = null;

  if (!updateVisitorInput) return updatedVisitor;

  try {
    const response = await API.graphql(graphqlOperation(updateVisitorMutation,
      {
        input: updateVisitorInput,
      })) as GraphQLResult<APIt.UpdateVisitorMutation>;
    debug(`updateVisitor() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.updateVisitor) {
      updatedVisitor = response.data.updateVisitor as APIt.Visitor;
    }
  } catch (error) {
    console.error(`updateVisitor(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`updateVisitor() updatedVisitor is ${JSON.stringify(updatedVisitor)}`);
  return(updatedVisitor);
};
updateVisitor = auditDecorator('updateVisitor', updateVisitor);

export let updateVisitorAccessLevelApproval = async (updateVisitorAccessLevelApprovalInput: APIt.UpdateVisitorAccessLevelApprovalInput): Promise<APIt.VisitorAccessLevelApproval | null> => {
  debug(`updateVisitorAccessLevelApproval() updateVisitorAccessLevelApprovalInput is ${JSON.stringify(updateVisitorAccessLevelApprovalInput)}`);

  let visitorAccessLevelApproval: APIt.VisitorAccessLevelApproval | null = null;

  if (!updateVisitorAccessLevelApprovalInput) return visitorAccessLevelApproval;

  updateVisitorAccessLevelApprovalInput.notes ? updateVisitorAccessLevelApprovalInput.notes = sqlEscapeString(updateVisitorAccessLevelApprovalInput.notes) : null;

  try {
    const response = await API.graphql(graphqlOperation(updateVisitorAccessLevelApprovalMutation,
      {
        input: updateVisitorAccessLevelApprovalInput,
      })) as GraphQLResult<APIt.UpdateVisitorAccessLevelApprovalMutation>;
    debug(`updateVisitorAccessLevelApproval() response is ${JSON.stringify(response)}`);
    if (response.data && response.data.updateVisitorAccessLevelApproval) {
      visitorAccessLevelApproval = response.data.updateVisitorAccessLevelApproval as APIt.VisitorAccessLevelApproval;
    }
  } catch (error) {
    console.error(`updateVisitorAccessLevelApproval(): error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`updateVisitorAccessLevelApproval() visitorAccessLevelApproval is ${JSON.stringify(visitorAccessLevelApproval)}`);
  return(visitorAccessLevelApproval);
};
updateVisitorAccessLevelApproval = auditDecorator('updateVisitorAccessLevelApproval', updateVisitorAccessLevelApproval);

export const handleApproval = async (
  visitorAccessLevelApproval: APIt.VisitorAccessLevelApproval,
  action: IVisitorAccessLevelWithAction,
  approverId: string): Promise<void> =>
{
  debug(`handleApproval() visitorAccessLevelApproval is ${JSON.stringify(visitorAccessLevelApproval)} action is ${JSON.stringify(action)} approverId is ${approverId}`);

  let submitAddAccessLevelResponse: APIt.BadgeAccessLevelRequestResponse | null = null;

  const getVisitorResponse = await API.graphql(graphqlOperation(getVisitor,
    {
      id: action.visitor_id
    })) as GraphQLResult<APIt.GetVisitorQuery>;
  debug(`handleApproval() getVisitorResponse is ${JSON.stringify(getVisitorResponse)}`);
  const visitor = getVisitorResponse.data?.getVisitor as APIt.Visitor; 

  const getVisitorAccessLevelResponse = await API.graphql(graphqlOperation(getVisitorAccessLevel,
    {
      id: visitorAccessLevelApproval.visitor_access_level_id
    })) as GraphQLResult<APIt.GetVisitorAccessLevelQuery>;
  debug(`handleApproval() getVisitorAccessLevelResponse is ${JSON.stringify(getVisitorAccessLevelResponse)}`);
  const visitorAccessLevel = getVisitorAccessLevelResponse.data?.getVisitorAccessLevel as APIt.VisitorAccessLevel; 

  // non employee visitor access level cannot be assigned until badge is assigned which happens at check in
  if (action.visitor_type == VisitorTypes.Employee) {
    let hoursExtAccessLevelActivation = 0;
    let hoursExtAccessLevelDeactivation = 0;
    try {
      hoursExtAccessLevelActivation = parseInt(
        (await queryLookupTypeValueForTypeAndDescription(
          LookupTypes.WelcomeApplicationSettings,
          WelcomeApplicationSettings.HoursExtensionAccessLevelActivation)).value);
      hoursExtAccessLevelDeactivation = parseInt(
        (await queryLookupTypeValueForTypeAndDescription(
          LookupTypes.WelcomeApplicationSettings,
          WelcomeApplicationSettings.HoursExtensionAccessLevelDeactivation)).value);
    } catch(error) {
      console.error('Error getting Hours Extension - Access Level... lookup type values', error);
    }
    debug(`handleApproval() hoursExtAccessLevelActivation is ${hoursExtAccessLevelActivation}`);
    debug(`handleApproval() hoursExtAccessLevelDeactivation is ${hoursExtAccessLevelDeactivation}`);
    submitAddAccessLevelResponse = await submitAddAccessLevel(
      action.person_id,
      action.access_level_id,
      action.permanent_flag,
      action.start_date ? (Date.parse(action.start_date+'Z')/1000) - (hoursExtAccessLevelActivation*60*60) : undefined,
      action.end_date ? (Date.parse(action.end_date+'Z')/1000) + (hoursExtAccessLevelDeactivation*60*60) : undefined);
    debug(`handleApproval() submitAddAccessLevelResponse is ${JSON.stringify(submitAddAccessLevelResponse)}`);
    if (submitAddAccessLevelResponse?.statusCode !== 202) throw new Error('submission failed');
  }

  if (action.visitor_type == VisitorTypes.UnescortedVendor
    && visitor.vendor_day_pass_badge_num !== null
    && visitor.vendor_day_pass_badge_num !== ''
    && (await getLookupTypeValueId(LookupTypes.VisitorRequestStatus, VisitorRequestStatus.CheckedIn)) == visitor.status_id)
  {
    // remove existing access level if it exists
    try {
      let badgeRemoveAccessLevelRequestResponse: APIt.BadgeAccessLevelRequestResponse | null = null;
      const removeAccessLevelInput = {
        methodName: 'RemoveAccessLevel',
        params: {
          AccessLevelID: visitorAccessLevel.access_level_id,
          Priority: UnicornPACSAPIv2Priority.HIGH,
          WelcomeID: visitorAccessLevel.visitor_id,
        }
      };
      debug(`handleApproval() removeAccessLevelInput is ${JSON.stringify(removeAccessLevelInput)}`);
      const removeAccessLevelResponse = await API.graphql(graphqlOperation(badgeAccessLevelMutation,
        {
          input: removeAccessLevelInput
        })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
      debug(`handleApproval() removeAccessLevelResponse is ${JSON.stringify(removeAccessLevelResponse)}`);
      if (removeAccessLevelResponse.data && removeAccessLevelResponse.data.badgeAccessLevel) {
        badgeRemoveAccessLevelRequestResponse = removeAccessLevelResponse.data.badgeAccessLevel as APIt.BadgeAccessLevelRequestResponse;
      }
      debug(`handleApproval() badgeRemoveAccessLevelRequestResponse for remove is ${JSON.stringify(badgeRemoveAccessLevelRequestResponse)}`);
      if (badgeRemoveAccessLevelRequestResponse?.requestUUID) await throttle(() => queryAccessControlRequestStatus(badgeRemoveAccessLevelRequestResponse!.requestUUID!), 500);
    } catch(error) {
      console.error(`handleApproval() error is ${JSON.stringify(error)}`);
      throw error;
    }

    try {
      let hoursExtAccessLevelActivation = 0;
      let hoursExtAccessLevelDeactivation = 0;
      try {
        hoursExtAccessLevelActivation = parseInt(
          (await queryLookupTypeValueForTypeAndDescription(
            LookupTypes.WelcomeApplicationSettings,
            WelcomeApplicationSettings.HoursExtensionAccessLevelActivation)).value);
        hoursExtAccessLevelDeactivation = parseInt(
          (await queryLookupTypeValueForTypeAndDescription(
            LookupTypes.WelcomeApplicationSettings,
            WelcomeApplicationSettings.HoursExtensionAccessLevelDeactivation)).value);
      } catch(error) {
        console.error('Error getting Hours Extension - Access Level... lookup type values', error);
      }
      debug(`handleApproval() hoursExtAccessLevelActivation is ${hoursExtAccessLevelActivation}`);
      debug(`handleApproval() hoursExtAccessLevelDeactivation is ${hoursExtAccessLevelDeactivation}`);
      const badgeAccessLevelInput = {
        methodName: 'AddAccessLevel',
        params: {
          AccessLevelID: visitorAccessLevel.access_level_id,
	        ActivateTimeEpoch: visitorAccessLevel.start_date
            ? (Date.parse(visitorAccessLevel.start_date+'Z')/1000) - (hoursExtAccessLevelActivation*60*60)
            : undefined,
	        DeactivateTimeEpoch: visitorAccessLevel.end_date
            ? (Date.parse(visitorAccessLevel.end_date+'Z')/1000) + (hoursExtAccessLevelDeactivation*60*60)
            : undefined,
          Priority: UnicornPACSAPIv2Priority.HIGH,
          WelcomeID: visitorAccessLevel.visitor_id,
        }
      };
      debug(`handleApproval() badgeAccessLevelInput is ${JSON.stringify(badgeAccessLevelInput)}`);
      const badgeAccessLevelResponse = await API.graphql(graphqlOperation(badgeAccessLevel,
        {
          input: badgeAccessLevelInput
        })) as GraphQLResult<APIt.BadgeAccessLevelMutation>;
      debug(`handleApproval() badgeAccessLevelResponse is ${JSON.stringify(badgeAccessLevelResponse)}`);
      if (badgeAccessLevelResponse.data?.badgeAccessLevel?.statusCode !== 202) throw new Error('Badge Assignment Failure');
      const updateVisitorAccessLevelInput = {
        id: visitorAccessLevelApproval.visitor_access_level_id,
        requestUUID: badgeAccessLevelResponse.data.badgeAccessLevel.requestUUID,
        updated_by: approverId,
      } as APIt.UpdateVisitorAccessLevelInput;
      const updateVisitorAccessLevelResponse = await updateVisitorAccessLevel(updateVisitorAccessLevelInput);
      debug(`handleApproval() updateVisitorAccessLevelResponse is ${JSON.stringify(updateVisitorAccessLevelResponse)}`);
    } catch(error: any) {
      console.error(`handleApproval(): AddAccessLevel exception is ${error} ${JSON.stringify(error)}`);
      if (typeof error == 'object' && error && error.errors) throw new Error(`Failed to assign access level: ${error.errors[0].message}`);
      throw new Error(`Failed to assign access level: ${error}`);
    }
  }

  const visitorAccessLevelApprovalStatusSubmittedId = await getLookupTypeValueId(LookupTypes.AccessLevelApprovalStatus,ApprovalStatus.Submitted);
  if (!visitorAccessLevelApprovalStatusSubmittedId) throw new Error('could not find request access level approval status Submitted id');
  const visitorAccessLevelApprovalStatusApprovedId = await getLookupTypeValueId(LookupTypes.AccessLevelApprovalStatus,ApprovalStatus.Approved);
  if (!visitorAccessLevelApprovalStatusApprovedId) throw new Error('could not find request access level approval status Approved id');

  visitorAccessLevelApproval.status_code_id = visitorAccessLevelApprovalStatusSubmittedId;
  visitorAccessLevelApproval.updated_by = approverId;
  visitorAccessLevelApproval.notes = action.notes;
  const updateVisitorAccessLevelApprovalInput = {
    id: visitorAccessLevelApproval.id,
    visitor_access_level_id: visitorAccessLevelApproval.visitor_access_level_id,
    approver_id: visitorAccessLevelApproval.approver_id,
    approver_source_system_id: visitorAccessLevelApproval.approver_source_system_id,
    status_code_id: action.visitor_type == VisitorTypes.Employee ? visitorAccessLevelApprovalStatusSubmittedId : visitorAccessLevelApprovalStatusApprovedId,
    notes: action.notes,
    updated_by: visitorAccessLevelApproval.approver_id,
  } as APIt.UpdateVisitorAccessLevelApprovalInput;
  const updateVisitorAccessLevelApprovalResponse = await updateVisitorAccessLevelApproval(updateVisitorAccessLevelApprovalInput);
  debug(`handleApproval() updateVisitorAccessLevelApprovalResponse is ${JSON.stringify(updateVisitorAccessLevelApprovalResponse)}`);

  const visitStatusScheduledVisitId = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus,VisitorRequestStatus.ScheduledVisit);
  if (!visitStatusScheduledVisitId) throw new Error('could not find visitor request status scheduled visit id');

  const updateVisitorAccessLevelInput = {
    id: action.id,
    status_code_id: action.visitor_type == VisitorTypes.Employee ? visitorAccessLevelApprovalStatusSubmittedId : visitorAccessLevelApprovalStatusApprovedId,
    requestUUID: submitAddAccessLevelResponse ? submitAddAccessLevelResponse?.requestUUID : null,
    updated_by: visitorAccessLevelApproval.approver_id,
  } as APIt.UpdateVisitorAccessLevelInput;
  const updateVisitorAccessLevelResponse = await updateVisitorAccessLevel(updateVisitorAccessLevelInput);
  debug(`handleApproval() updateVisitorAccessLevelResponse is ${JSON.stringify(updateVisitorAccessLevelResponse)}`);
  const visitorRequestStatusTypeValues = await queryLookupTypeValues(LookupTypes.VisitorRequestStatus);
  debug(`handleApproval() visitorRequestStatusTypeValues is ${JSON.stringify(visitorRequestStatusTypeValues)}`);
  if (visitorRequestStatusTypeValues.find(v => v.id == visitor.status_id)?.value == VisitorRequestStatus.PendingApproval) {
    const updateVisitorInput: APIt.UpdateVisitorInput = {
      id: action.visitor_id,
      updated_by: approverId,
      requestUUID: undefined,
      status_id: visitStatusScheduledVisitId,
    };
    const updateVisitorResponse  = await updateVisitor(updateVisitorInput);
    debug(`handleApproval() updateVisitorResponse is ${JSON.stringify(updateVisitorResponse)}`);
  }

  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(sNSPublishAccessLevelRequestApproved,
      {
        visitorAccessLevelId: visitorAccessLevelApproval.visitor_access_level_id,
      })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestApprovedQuery>;
    if (response && response.data && response.data.SNSPublishAccessLevelRequestApproved) {
      debug(`handleApproval() sNSPublishAccessLevelRequestApproved response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`handleApproval() sNSPublishAccessLevelRequestApproved error is ${JSON.stringify(error)}`);
    throw error;
  }
};

export const handleDenial = async (
  visitorAccessLevelApproval: APIt.VisitorAccessLevelApproval,
  action: IVisitorAccessLevelWithAction,
  approverId: string): Promise<void> =>
{
  debug(`handleDenial() visitorAccessLevelApproval is ${JSON.stringify(visitorAccessLevelApproval)} action is ${JSON.stringify(action)} approverId is ${approverId}`);
  const visitorAccessLevelApprovalStatusDeniedId = await getLookupTypeValueId(LookupTypes.AccessLevelApprovalStatus, ApprovalStatus.Denied);
  if (!visitorAccessLevelApprovalStatusDeniedId) throw new Error('could not find request access level approval status Approved id');
  const visitorAccessLevelApprovalDenialId = await getLookupTypeValueId(LookupTypes.AccessLevelDenial, action.denialReason!);
  if (!visitorAccessLevelApprovalDenialId) throw new Error('could not find request access level approval denial reason id');
  visitorAccessLevelApproval.status_code_id = visitorAccessLevelApprovalStatusDeniedId;
  visitorAccessLevelApproval.denial_reason_id = visitorAccessLevelApprovalDenialId;
  visitorAccessLevelApproval.updated_by = approverId;
  visitorAccessLevelApproval.notes = action.notes;
  const updateVisitorAccessLevelApprovalInput = {
    id: visitorAccessLevelApproval.id,
    visitor_access_level_id: visitorAccessLevelApproval.visitor_access_level_id,
    approver_id: visitorAccessLevelApproval.approver_id,
    approver_source_system_id: visitorAccessLevelApproval.approver_source_system_id,
    status_code_id: visitorAccessLevelApprovalStatusDeniedId,
    denial_reason_id: visitorAccessLevelApprovalDenialId,
    notes: action.notes,
    updated_by: visitorAccessLevelApproval.approver_id,
  } as APIt.UpdateVisitorAccessLevelApprovalInput;
  await updateVisitorAccessLevelApproval(updateVisitorAccessLevelApprovalInput);
  let updateVisitorAccessLevelInput = {
    id: action.id,
    status_code_id: visitorAccessLevelApprovalStatusDeniedId,
    updated_by: visitorAccessLevelApproval.approver_id,
  } as APIt.UpdateVisitorAccessLevelInput;
  await updateVisitorAccessLevel(updateVisitorAccessLevelInput);
  const visitStatusDeniedId = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus,VisitorRequestStatus.Denied);
  if (!visitStatusDeniedId) throw new Error('could not find visitor request status denied id');
  const getVisitorResponse = await API.graphql(graphqlOperation(getVisitor,
    {
      id: action.visitor_id
    })) as GraphQLResult<APIt.GetVisitorQuery>;
  debug(`handleDenial() getVisitorResponse is ${JSON.stringify(getVisitorResponse)}`);
  const visitor = getVisitorResponse.data?.getVisitor as APIt.Visitor; 
  const visitorRequestStatusTypeValues = await queryLookupTypeValues(LookupTypes.VisitorRequestStatus);
  debug(`handleDenial() visitorRequestStatusTypeValues is ${JSON.stringify(visitorRequestStatusTypeValues)}`);
  const getVisitorAccessLevelsResponse = await API.graphql(graphqlOperation(listVisitorAccessLevelsForRequestAndVisitor,
    {
      visitor_id: action.visitor_id,
      request_id: action.request_id
    })) as GraphQLResult<APIt.ListVisitorAccessLevelsForRequestAndVisitorQuery>;
  debug(`handleDenial() getVisitorAccessLevelsResponse is ${JSON.stringify(getVisitorAccessLevelsResponse)}`);
  const accessLevels = getVisitorAccessLevelsResponse.data?.listVisitorAccessLevelsForRequestAndVisitor;
  debug(`handleDenial() accessLevels is ${JSON.stringify(accessLevels)}`);
  if (!accessLevels?.find(al => al?.status !== VisitorRequestStatus.Denied)) {
    const updateVisitorInput: APIt.UpdateVisitorInput = {
      id: action.visitor_id,
      updated_by: approverId,
      requestUUID: undefined,
      status_id: visitStatusDeniedId,
    };
    debug(`handleDenial() updateVisitorInput is ${JSON.stringify(updateVisitorInput)}`);
    const updateVisitorResponse  = await updateVisitor(updateVisitorInput);
    debug(`handleDenial() updateVisitorResponse is ${JSON.stringify(updateVisitorResponse)}`);
  }

  // send to SNS Topic
  try {
    const response = await API.graphql(graphqlOperation(sNSPublishAccessLevelRequestDeclined,
      {
        visitorAccessLevelId: visitorAccessLevelApproval.visitor_access_level_id,
      })) as GraphQLResult<APIt.SNSPublishAccessLevelRequestDeclinedQuery>;
    if (response && response.data && response.data.SNSPublishAccessLevelRequestDeclined) {
      debug(`handleDenial() sNSPublishAccessLevelRequestDeclined response is ${JSON.stringify(response)}`);
    }
  } catch(error) {
    console.error(`handleDenial() sNSPublishAccessLevelRequestDeclined error is ${JSON.stringify(error)}`);
    throw error;
  }

};

const handleEditDates = async (
  action: IVisitorAccessLevelWithAction,
  approverId: string) => {
  let updateVisitorAccessLevelInput = {
    dates_updated: action.dates_updated,
    end_date: !action.permanent_flag ? `${action.end_date}` : undefined,
    id: action.id,
    permanent_flag: action.permanent_flag,
    start_date: !action.permanent_flag ? `${action.start_date}` : undefined,
    updated_by: approverId
  };
  await updateVisitorAccessLevel(updateVisitorAccessLevelInput);
};

const handleSiteAccessApproval = async (visitorAccessLevelApproval: APIt.VisitorAccessLevelApproval, action: IVisitorAccessLevelWithAction, approverId: string): Promise<void> => {
  debug(`handleSiteAccessApproval() visitorAccessLevelApproval is ${JSON.stringify(visitorAccessLevelApproval)}`);
  const visitorAccessLevelApprovalStatusApprovedId = await getLookupTypeValueId(LookupTypes.AccessLevelApprovalStatus,ApprovalStatus.Approved);
  if (!visitorAccessLevelApprovalStatusApprovedId) throw new Error('could not find request access level approval status approved id');
  visitorAccessLevelApproval.status_code_id = visitorAccessLevelApprovalStatusApprovedId;
  visitorAccessLevelApproval.updated_by = approverId;
  visitorAccessLevelApproval.notes = action.notes;
  const updateVisitorAccessLevelApprovalInput = {
    id: visitorAccessLevelApproval.id,
    visitor_access_level_id: visitorAccessLevelApproval.visitor_access_level_id,
    approver_id: visitorAccessLevelApproval.approver_id,
    approver_source_system_id: visitorAccessLevelApproval.approver_source_system_id,
    status_code_id: visitorAccessLevelApprovalStatusApprovedId,
    notes: action.notes,
    updated_by: visitorAccessLevelApproval.approver_id,
  } as APIt.UpdateVisitorAccessLevelApprovalInput;
  await updateVisitorAccessLevelApproval(updateVisitorAccessLevelApprovalInput);
  const updateVisitorAccessLevelInput = {
    id: action.id,
    status_code_id: visitorAccessLevelApprovalStatusApprovedId,
    updated_by: visitorAccessLevelApproval.approver_id,
  } as APIt.UpdateVisitorAccessLevelInput;
  await updateVisitorAccessLevel(updateVisitorAccessLevelInput);
  const visitStatusScheduledVisitId = await getLookupTypeValueId(LookupTypes.VisitorRequestStatus,VisitorRequestStatus.ScheduledVisit);
  if (!visitStatusScheduledVisitId) throw new Error('could not find visitor request status scheduled visit id');
  const updateVisitorInput: APIt.UpdateVisitorInput = {
    id: action.visitor_id,
    updated_by: visitorAccessLevelApproval.approver_id,
    status_id: visitStatusScheduledVisitId,
  };
  await updateVisitor(updateVisitorInput);
};

export interface SubmitVisitorAccessLevelActionsProps {
  actions: IVisitorAccessLevelWithAction[];
  approverId: string;
}

export let submitVisitorAccessLevelActions = async (props: SubmitVisitorAccessLevelActionsProps): Promise<void> => {
  debug(`submitVisitorAccessLevelActions() props is ${JSON.stringify(props)}`);

  for (let action of props.actions) {
    try {
      debug(`submitVisitorAccessLevelActions() action is ${JSON.stringify(action)}`);
      const visitorAccessLevelApproval = await getVisitorAccessLevelApproval(action.id, props.approverId);
      if (!visitorAccessLevelApproval) throw new Error('could not find request access level approval');
      debug(`submitVisitorAccessLevelActions() visitorAccessLevelApproval is ${JSON.stringify(visitorAccessLevelApproval)}`);
      if (action.access_level_name != 'Site Access') {
        switch (action.action?.value) {
          case 'approve':
            await handleApproval(visitorAccessLevelApproval, action, props.approverId);
            break;
          case 'deny':
            await handleDenial(visitorAccessLevelApproval, action, props.approverId);
            break;
          case 'edit-dates':
            await handleEditDates(action, props.approverId);
            break;
        }
      } else if (action.access_level_name == 'Site Access') {
        switch (action.action?.value) {
          case 'approve':
            await handleSiteAccessApproval(visitorAccessLevelApproval, action, props.approverId);
            break;
          case 'deny':
            await handleDenial(visitorAccessLevelApproval, action, props.approverId);
            break;
          case 'edit-dates':
            await handleEditDates(action, props.approverId);
            break;
        }
      }
    } catch (error) {
      debug(`submitVisitorAccessLevelActions() error is ${error} JSON: ${JSON.stringify(error)}`);
      throw (error);
    }
  }

};
submitVisitorAccessLevelActions = auditDecorator('submitVisitorAccessLevelActions', submitVisitorAccessLevelActions);